import './bnb.css';
// import Navbar from '../../components/navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBuilding} from '@fortawesome/free-solid-svg-icons';
import useFetch from "../../hooks/useFetch";
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';
import {useState, useEffect} from 'react';
import {React} from 'react';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import {Link} from "react-router-dom";
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import {faSquareParking,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';

const Grecale = () => {

useEffect(() => {
window.scrollTo(0, 0);
  }, []);

  const { data, loading, error } = useFetch("/api/bnbs/grecale/");

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;
    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? photos.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === photos.length - 1 ? 0 : slideNumber + 1;
    }
    setSlideNumber(newSlideNumber);
  };

  // Verifica se i dati stanno caricando
  if (loading) {
    return <div>Loading...</div>;
  }

  // Verifica se c'è un errore
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Verifica se i dati sono definiti e non sono vuoti
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Verifica la struttura dei dati
  console.log('Fetched data:', data);

  if (!data[0].availability) {
    return <div>No availability data</div>;
  }

  const ciao = data[0].availability;

  const renderCell = (date) => {
    const dateString = date.toISOString().slice(0, 10); // Ottieni la stringa della data (es. '2024-04-25')

    // Trova la disponibilità corrispondente alla data selezionata
    const dayAvailability = ciao.find(item => item.availableDate === dateString);

    if (!dayAvailability || !dayAvailability.available) {
      return <Badge className="calendar-unavailable-badge" />;
    }

    return <Badge content={`€${dayAvailability.price}`} />;
  };

	const photos = [
		{
			src: "https://i.imgur.com/xvhoBVO.jpeg"
		},

		{
			src: "https://i.imgur.com/KW2r36J.jpeg"
		},

		{
			src: "https://i.imgur.com/1lwZ031.jpeg"
		},

		{
			src: "https://i.imgur.com/Mm5ClF7.jpeg"
		},

		{
			src: "https://i.imgur.com/sNei2ux.jpeg"
		},

		{
			src: "https://i.imgur.com/nRG3iTe.jpeg"
		},

		{
			src: "https://i.imgur.com/LNPHUCn.jpeg"
		},

		{
			src: "https://i.imgur.com/8F1ig44.jpeg"
		},

		{
			src: "https://i.imgur.com/nhxrgUp.jpeg"
		}

	];

	return(
		<div>
			<Link to="/bnbs" style={{color:"inherit", textDecoration:"none"}}>
			<section className="optionintro">
			<img src="https://i.imgur.com/BCfRaeg.png"/>
	    	</section>
	    	</Link>
			<div className="bContainer">
				{open && <div className="slider">
					<FontAwesomeIcon icon={faCircleXmark} className="close" onClick={()=>setOpen(false)}/>
					<FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={()=>handleMove("l")}/>
					<div className="sliderWrapper">
						<img src={photos[slideNumber].src} alt="" className="sliderImg" />
					</div>
					<FontAwesomeIcon icon={faCircleArrowRight} className="arrow" onClick={()=>handleMove("r")}/>
					</div>}
				<div className="bWrapper">
					<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sulla%20camera%20Grecale" style={{color:"inherit", textDecoration:"none"}}>
					<button className="bookNow">
						Prenota ora!
					</button>
					</Link>
					<h1 className="siTitle"> Grecale </h1>
					<span className="siSubtitle">
						Camera Matrimoniale con Vista Giardino
					</span>
					<span className="siCaratt">
						Camera ・ 1 bagno ・ 28mq ・ 1 letto matrimoniale
					</span>
					<div className="bAddress">
						<Link to="https://maps.app.goo.gl/P2xzjzb56cSXy2Nx8?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
						<FontAwesomeIcon icon={faLocationDot} className="geodot"/>
						<span>Contrada Cerasa, 20/b, Vieste, 71019</span>
						</Link>
					</div>
{/*					<span className="bDistance">
						Excellent location - 500m from center
					</span>
					<span className="bPriceHighlight">
						Prenota e avrai gratis ...
					</span>*/}
					<div className="bImages">
						{photos.map((photo,i) =>(
							<div className="bImgWrapper">
								<img onClick={()=>handleOpen(i)} src={photo.src} alt="" className="bImg" />
							</div>
						))}
					</div>
					<div className="bDetails">
						<div className="bDetailsTexts">
							<h1 className="bTitle">Camera Matrimoniale con Vista Giardino</h1>
							<p className="bDesc">
								Le camere del B&B Valle degli Ulivi si trovano al piano superiore di una palazzina di recente costruzione.<hr/>
								La camera Grecale è una camera matrimoniale di 28 mq, perfetta per una coppia in cerca di pace e relax.
								La camera è dotata di tutti i comfort: Wi-Fi, balcone con vista panoramica e salottino, Frigo Bar, Asciugacapelli, Smart TV, bollitore con tisane e tè, pulizia giornaliera.<hr/>
								La struttura è recintata con all'interno un ampio parcheggio, un immenso prato con area solarium e un parco giochi per bambini. 
								A circa due minuti di macchina ( 1500 m circa ) si trova la spiaggia di S. Lorenzo, con sabbia dorata finissima, mentre quella di Pizzomunno è a 2,1 Km. 
								Dalla struttura è possibile fare passeggiate rilassanti nel verde della zona oppure raggiungere la Foresta Umbra a soli 5-6 Km o raggiungere un maneggio a soli 2 Km per chi ama le passeggiate a cavallo. Il verde è il colore predominante, non manca il silenzio per rilassarsi in tutta tranquillità. 
								Servizi gratuiti sono: Wi-Fi, solarium attrezzato e parcheggio interno privato.
								Tutti gli ospiti sono i benvenuti. Michele e Leonardo, entusiasti di ospitarvi, sono sempre a disposizione degli ospiti per qualsiasi informazione su luoghi da visitare o prodotti da degustare. Non esitate a chiederci dove trovare i migliori ristoranti e agriturismi per poter gustare piatti tipici locali o masserie dove degustare e acquistare formaggi e latticini locali. 
							</p>
							<h1 className="bTitle">La Struttura - Da Sapere</h1>
							<p className="bDesc">
								Il check-in è dalle 15:00 alle 18:00. Si prega gentilmente di comunicare in anticipo l'ora di arrivo. Purtroppo non è possibile in alcun modo anticipare il check-in.<hr/>
								Il check-out è dalle 07:00 alle 10:00. Si prega gentilmente di rispettare l'orario di check-out, al fine di permettere la pulizia delle camere per gli ospiti successivi.<hr/>
								Al check-in gli ospiti devono esibire un documento d'identità con foto. Siete pregati di notare che le Richieste Speciali sono soggette a disponibilità, e potrebbero comportare l'addebito di un supplemento.<hr/>
								La colazione all'italiana viene servita dalle 08:00 alle 09:00. Si prega di comunicare in anticipo eventuali allergie o intolleranze. <hr/>
								La piscina è aperta nel periodo 01/06-30/09, nelle fasce orarie: 09:30 - 12:30   /    16:30 - 19:30. <hr/>
								Si prega i gentili ospiti di munirsi di cuffia per l'utilizzo della piscina. <hr/>
							</p>
						</div>
						<div className="bDetailsPrice">
							<h1>Perfetto per 2 persone</h1>
							<span>
								Situati a 1500m dal mare e dal centro, abbiamo un punteggio di 9.5 su Booking.com!
							</span>
							<a href="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sulla%20camera%20Grecale" class="text-decoration-none">
								<button className="linkbut">
									Prenota ora!
								</button>
							</a>
						</div>
					</div>
					<div className="bServices">
						<div className="bServicesTexts">
							<h1 className="bTitle">I nostri servizi</h1>
						</div>
						<div className="serviceList">
							<div className="serviceListItem">
								<TbAirConditioning className="bnbicon"/>
								<span>Aria Condizionata</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faSquareParking} className="bnbicon"/>
								<span>Parcheggio interno</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faWifi} className="bnbicon"/>
								<span>WiFi</span>
							</div>
							<div className="serviceListItem">
								<GiCoffeePot className="bnbicon"/>
								<span>Bollitore</span>
							</div>
							<div className="serviceListItem">
								<MdBalcony className="bnbicon"/>
								<span>Balcone con vista</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faTv} className="bnbicon"/>
								<span>Smart TV</span>
							</div>
							<div className="serviceListItem">
								<MdDryCleaning className="bnbicon"/>
								<span>Pulizia giornaliera</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faMugSaucer} className="bnbicon"/>
								<span>Colazione all'italiana</span>
							</div>
						</div>
						<div className="bServicesTexts">
							<h1 className="bTitle">Le nostre disponibilità</h1>
							<span className="bspan">*I prezzi non includono la tassa di soggiorno, pari a 2,50€ per persona per notte</span>
						</div>	
						<div className="cal">
							<Calendar
					            bordered
					            renderCell={renderCell}
					            // onSelect={handleDateSelect}
					            // showWeekNumbers
					        />
			    		</div>					
					</div>
				</div>
			</div>
			<div className="lastitems">
				<Weareon />
				<Footer />
			</div>
		</div>
	);
}

export default Grecale;

