import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './pages/home/Home';
import HomeEn from './pages/home/Home_en';
import List from './pages/list/List';
import ListEn from './pages/list/List_en';
import AppList from './pages/applist/Applist';
import AppListEn from './pages/applist/Applist_en';
import VdV from './pages/vdv/VdV';
import VdVEn from './pages/vdv/VdV_en';
import Scirocco from './pages/bnb/Scirocco';
import SciroccoEn from './pages/bnb/Scirocco_en';
import Grecale from './pages/bnb/Grecale';
import GrecaleEn from './pages/bnb/Grecale_en';
import Libeccio from './pages/bnb/Libeccio';
import LibeccioEn from './pages/bnb/Libeccio_en';
import Maestrale from './pages/bnb/Maestrale';
import MaestraleEn from './pages/bnb/Maestrale_en';
import Uno from './pages/appartamenti/1';
import UnoEn from './pages/appartamenti/1_en';
import Due from './pages/appartamenti/2';
import DueEn from './pages/appartamenti/2_en';
import Tre from './pages/appartamenti/3';
import TreEn from './pages/appartamenti/3_en';
import Quattro from './pages/appartamenti/4';
import QuattroEn from './pages/appartamenti/4_en';
import Cinque from './pages/appartamenti/5';
import CinqueEn from './pages/appartamenti/5_en';
import Sei from './pages/appartamenti/6';
import SeiEn from './pages/appartamenti/6_en';
import Sette from './pages/appartamenti/7';
import SetteEn from './pages/appartamenti/7_en';
import Olio from './pages/olio/Olio';
import OlioEn from './pages/olio/Olio_en';
import Calendario from './pages/calendario/Calendario';
import CalendarioEn from './pages/calendario/Calendario_en';
import Contatti from './pages/contatti/Contatti';
import ContattiEn from './pages/contatti/Contatti_en';
import Guida from './pages/guida/Guida';
import GuidaEn from './pages/guida/Guida_en';
import Escursioni from './pages/escursioni/Escursioni';
import EscursioniEn from './pages/escursioni/Escursioni_en';
import Luoghi from './pages/luoghi/Luoghi';
import LuoghiEn from './pages/luoghi/Luoghi_en';
import Spiagge from './pages/spiagge/Spiagge';
import SpiaggeEn from './pages/spiagge/Spiagge_en';
import OspitiGuida from './pages/ospitiguida/Ospitiguida';
import OspitiGuidaEn from './pages/ospitiguida/Ospitiguida_en';
import OspitiSpiagge from './pages/ospiti_spiagge/OspitiSpiagge';
import OspitiSpiaggeEn from './pages/ospiti_spiagge/OspitiSpiagge_en';
import OspitiEscursioni from './pages/ospiti_escursioni/OspitiEscursioni';
import OspitiEscursioniEn from './pages/ospiti_escursioni/OspitiEscursioni_en';
import OspitiLuoghi from './pages/ospiti_luoghi/OspitiLuoghi';
import OspitiLuoghiEn from './pages/ospiti_luoghi/OspitiLuoghi_en';
import Ristoranti from './pages/ristoranti/Ristoranti';
import RistorantiEn from './pages/ristoranti/Ristoranti_en';
import Parcheggio from './pages/parcheggio/Parcheggio';
import ParcheggioEn from './pages/parcheggio/Parcheggio_en';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/bnbs" element={<List/>}></Route>
        <Route path="/bnbs/scirocco" element={<Scirocco/>}></Route>
        <Route path="/bnbs/grecale" element={<Grecale/>}></Route>
        <Route path="/bnbs/libeccio" element={<Libeccio/>}></Route>
        <Route path="/bnbs/maestrale" element={<Maestrale/>}></Route>
        <Route path="/appartamenti" element={<AppList/>}></Route>
        <Route path="/appartamenti/uno" element={<Uno/>}></Route>
        <Route path="/appartamenti/due" element={<Due/>}></Route>
        <Route path="/appartamenti/tre" element={<Tre/>}></Route>
        <Route path="/appartamenti/quattro" element={<Quattro/>}></Route>
        <Route path="/appartamenti/cinque" element={<Cinque/>}></Route>
        <Route path="/appartamenti/sei" element={<Sei/>}></Route>
        <Route path="/appartamenti/sette" element={<Sette/>}></Route>
        <Route path="/vdv" element={<VdV/>}></Route>
        <Route path="/olio" element={<Olio/>}></Route>
        <Route path="/disponibilita" element={<Calendario/>}></Route>
        <Route path="/contatti" element={<Contatti/>}></Route>
        <Route path="/guida" element={<Guida/>}></Route>
        <Route path="/guida/spiagge" element={<Spiagge/>}></Route>
        <Route path="/guida/escursioni-e-tour" element={<Escursioni/>}></Route>
        <Route path="/guida/luoghi-di-interesse" element={<Luoghi/>}></Route>
        <Route path="/ospiti/guida" element={<OspitiGuida/>}></Route>
        <Route path="/ospiti/guida/spiagge" element={<OspitiSpiagge/>}></Route>
        <Route path="/ospiti/guida/escursioni-e-tour" element={<OspitiEscursioni/>}></Route>
        <Route path="/ospiti/guida/luoghi-di-interesse" element={<OspitiLuoghi/>}></Route>
        <Route path="/ospiti/guida/ristoranti" element={<Ristoranti/>}></Route>
        <Route path="/ospiti/guida/parcheggio" element={<Parcheggio/>}></Route>
        {/*ENGLISH VERSION*/}
        <Route path="/len=en" element={<HomeEn/>}></Route>
        <Route path="/len=en/bnbs" element={<ListEn/>}></Route>
        <Route path="/len=en/bnbs/grecale" element={<GrecaleEn/>}></Route>
        <Route path="/len=en/bnbs/scirocco" element={<SciroccoEn/>}></Route>
        <Route path="/len=en/bnbs/libeccio" element={<LibeccioEn/>}></Route>
        <Route path="/len=en/bnbs/maestrale" element={<MaestraleEn/>}></Route>
        <Route path="/len=en/appartamenti" element={<AppListEn/>}></Route>
        <Route path="/len=en/appartamenti/uno" element={<UnoEn/>}></Route>
        <Route path="/len=en/appartamenti/due" element={<DueEn/>}></Route>
        <Route path="/len=en/appartamenti/tre" element={<TreEn/>}></Route>
        <Route path="/len=en/appartamenti/quattro" element={<QuattroEn/>}></Route>
        <Route path="/len=en/appartamenti/cinque" element={<CinqueEn/>}></Route>
        <Route path="/len=en/appartamenti/sei" element={<SeiEn/>}></Route>
        <Route path="/len=en/appartamenti/sette" element={<SetteEn/>}></Route>
        <Route path="/len=en/olio" element={<OlioEn/>}></Route>
        <Route path="/len=en/vdv" element={<VdVEn/>}></Route>
        <Route path="/len=en/guida" element={<GuidaEn/>}></Route>
        <Route path="/len=en/guida/spiagge" element={<SpiaggeEn/>}></Route>
        <Route path="/len=en/guida/escursioni-e-tour" element={<EscursioniEn/>}></Route>
        <Route path="/len=en/guida/luoghi-di-interesse" element={<LuoghiEn/>}></Route>
        <Route path="/len=en/ospiti/guida" element={<OspitiGuidaEn/>}></Route>
        <Route path="/len=en/ospiti/guida/spiagge" element={<OspitiSpiaggeEn/>}></Route>
        <Route path="/len=en/ospiti/guida/escursioni-e-tour" element={<OspitiEscursioniEn/>}></Route>
        <Route path="/len=en/ospiti/guida/luoghi-di-interesse" element={<OspitiLuoghiEn/>}></Route>
        <Route path="/len=en/ospiti/guida/ristoranti" element={<RistorantiEn/>}></Route>
        <Route path="/len=en/ospiti/guida/parcheggio" element={<ParcheggioEn/>}></Route>
        <Route path="/len=en/contatti" element={<ContattiEn/>}></Route>
        <Route path="/len=en/disponibilita" element={<CalendarioEn/>}></Route>
        {/*<Route path="/bnb/:id" element={<BnB/>}></Route>*/}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
