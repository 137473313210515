import "./appartamentiAd.css";
import {Link} from "react-router-dom";


const AppartamentiAdEn = () => {
	return(
		<div className="appartamentiAd">
			<Link to="/len=en/appartamenti" style={{color:"inherit", textDecoration:"none"}}>
			<div className="appartamentiAdItem">
				<img src="https://i.imgur.com/91AEZz4.jpeg" className="appartamentiAdImg" />
			</div>
			</Link>
		</div>
	);
}

export default AppartamentiAdEn;