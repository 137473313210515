import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import FooterEn from '../../components/footer/Footer_en';

const SpiaggeEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/zyfnf56.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> BEACHES </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/r9Fnqzh.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lungomare Europa </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Sand beach
						</span>
						<span className="spiaggeFeatures">
							This is the northern coast, as well as the closest to our structure. 
							It extends for approximately 3 km. 
							Continuing north, you reach the "Porticello" seafront, 
							and then end in Peschici.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/vzkCALGUEWhZVGEy6?g_st=in&_imcp=1" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Europa,%2071019%20Vieste%20FG,%20Italia&ll=41.885136,16.172717&q=Lungomare%20Europa&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/zjQV66R.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lungomare Mattei </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Sand beach
						</span>
						<span className="spiaggeFeatures">
							This is the southern coast. 
							It extends for approximately 4 km. Continuing south, 
							you can reach fantastic places such as Mattinata and Manfredonia.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/2DwACdRYZjqmYVJMA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/QSMrjrd.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Vignanotica Bay </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Pebble beach
						</span>
						<span className="spiaggeFeatures">
							It is a splendid pebble beach, about 500 meters long, 
							characterized by the presence of multiple caves dug by the sea 
							and by the high, white limestone cliff behind it, which is very steep.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/YuZHL1GQkNhofaPc9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Vignanotica,%2071019%20Vieste%20FG,%20Italia&auid=4252621985428501806&ll=41.761233,16.164810&lsp=9902&q=Spiaggia%20Di%20Vignanotica&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/WCTpygR.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Campi Bay </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Pebble beach
						</span>
						<span className="spiaggeFeatures">
							It is located about 10 km south of Vieste and is surrounded by a beautiful Mediterranean 
							landscape with Aleppo pines. Here we find a beautiful pebble beach 500 meters long, 
							with a deep seabed, while in the center of the bay there are also two islets.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/AgWxfVKFGBaNdpiQ9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=8801275055752316386&ll=41.815425,16.194729&lsp=9902&q=Camping%20Calacampi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/G40vYEI.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Portogreco Bay </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Pebble beach
						</span>
						<span className="spiaggeFeatures">
							The Bay of Portogreco is a small natural "port" immersed in the verdant Mediterranean scrub. 
							The beach, 84 meters wide, has deep seabed. 
							By sea it is also possible to reach the natural arch of Porto Greco, a typical architect of the Gargano coast.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/c77cetwNPxRqad6V8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Porto%20Greco,%2071019%20Vieste%20FG,%20Italia&auid=12328222123947254497&ll=41.800019,16.192678&lsp=9902&q=Spiaggia%20di%20Porto%20Greco&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/7oe3Lfk.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Gattarella Beach</h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Sand beach
						</span>
						<span className="spiaggeFeatures">
							The Gattarella beach, 400 meters long, is located south of Vieste. 
							The beach owes its name to the historic Saracen tower present. 
							When the tide is low and the sea is calm or not very rough, the islet becomes a destination for romantic swimming excursions.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/9M2c9PonLvSVkK3s7?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%20179%E2%80%93181,%2071019%20Vieste,%20Italia&auid=16542512693579070607&ll=41.840900,16.184300&lsp=9902&q=Canale%20Porto%20Nuovo-%20Corr.%20Foce&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/2zakSGu.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Scialmarino</h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Sand beach
						</span>
						<span className="spiaggeFeatures">
							Also known as "Long Beach", Scialmarino Beach is located about 4 km from Vieste on the coast towards Peschici. 
							It is a golden sandy beach, almost 3 kilometers long, with a gently sloping seabed. Its bay is very suggestive and also offers the view of a trebuchet.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/ze98ty3X3Y2qwgUj7?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=9433542839651370045&ll=41.912366,16.132759&lsp=9902&q=Tra%20Sp.scialmarino%20E%20T.re%20Porticello&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<FooterEn />
		</div>
	);
}

export default SpiaggeEn;



