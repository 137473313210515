import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import FooterEn from '../../components/footer/Footer_en';

const LuoghiEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/w5foY8C.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> INTERESTING SITES </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/zmheTTY.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Old Town </h1>
						<span className="spiaggeFeatures">
							The historic center stands compactly on a rock overlooking the sea. 
							The ancient village, of medieval origins, has kept its original characteristics almost intact, 
							with its characteristic irregular streets, small squares/viewpoints and terraced houses.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/xeXVFrWxW9p7qp9z5?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Vescovado%2016,%2071019%20Vieste%20FG,%20Italia&auid=7202429120802604223&ll=41.881434,16.180171&lsp=9902&q=Cattedrale%20di%20Santa%20Maria%20Assunta&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/mOYQDZV.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Swabian Castle </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							The Castle of Vieste, which dominates the historic center with its imposing mass, 
							dates back to the second half of the century. XI, when the Norman Roberto Drengot was count of Vieste. 
							It has a triangular plan, accompanied by three spearhead bastions at the corners, which incorporate the older ones with a circular base and is a structure with a defensive function.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/Lwd6oHX36qWvQtSk6?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Largo%20Castello%2011,%2071019%20Vieste%20FG,%20Italia&auid=18314850826097158027&ll=41.880961,16.179229&lsp=9902&q=Castello%20Svevo%20Aragonese&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/0sew6NB.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Trabucchi </h1>
						<span className="spiaggeFeatures">
							Trabucchi are ancient fishing machines dating back to the 19th century, 
							according to historians they are an invention of the Phoenician people. 
							Famous are those of "Punta San Francesco", "Baia San Lorenzo", "Scialmarino", "Molinella".
						</span>
						<span className="spiaggeFeatures">
							Click below for directions ("Scialmarino"):
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/s96bXKPh3cquEbmeA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=5090710702777020403&ll=41.914264,16.134902&lsp=9902&q=Il%20Trabucco%20di%20Scialmarino&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/fOVnQlp.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Stairway of Love </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							The stairway of love was created on the occasion of the 1st Vieste in Love, painting one of the stairs that connect the upper gate of the old city with Corso Fazzini. 
							On the steps of this singular staircase there is the text of the song "The Legend of Pizzomunno and Cristalda" by Max Gazzè, which tells the legend of Pizzomunno and Cristalda.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/W1F2KCy5uYNeykuTA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Deputato%20Petrone%2013,%2071019%20Vieste%20FG,%20Italia&auid=15642170791326721239&ll=41.882501,16.180143&lsp=9902&q=Scalinata%20dell%E2%80%98%20Amore&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/0RwBl5N.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Pizzomunno </h1>
						<span className="spiaggeFeatures">
							Pizzomunno is an imposing limestone monolith 25 meters high. 
							It is located at the beginning of the beach south of the town centre, 
							called "del Castello" or "Scialara" or "del Pizzomunno". 
							Due to its grandeur it seems to stand almost as a guard over Vieste and due to its charm it has become the very symbol of Vieste.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/1RQNQEP7KKVLvmou8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/J7I7ltZ.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> St. Eufemia Lighthouse </h1>
						<span className="spiaggeFeatures">
							The Vieste lighthouse, which can be admired from much of the city, 
							stands on the islet of Santa Eufemia, located between Punta Santa Croce and Punta San Francesco. 
							Its position is strategic for the navigation routes between the middle and lower Adriatic. It was designed in 1867 and is currently uninhabited following the automation of the lighting system.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/RBCJEq8Dp9Xw2kTu8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Vieste%20FG,%20Italia&auid=6195786736746575652&ll=41.889177,16.184292&lsp=9902&q=Faro%20di%20Vieste&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/Gzxxt0v.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Umbra Forest </h1>
						<span className="spiaggeFeatures">
							The Foresta Umbra nature reserve is a protected natural area located in the innermost part of the Gargano National Park. 
							It owes its name to the dense vegetation which makes it very shady in many places. 
							The Umbrian forest has a surface area of approximately 10,000 hectares and has a rugged geography.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/KbhJptJ6m7BU93er8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=SP%2052%20bis,%2071018%20Vico%20del%20Gargano%20Puglia,%20Italia&ll=41.870098,16.052626&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<FooterEn />
		</div>
	);
}

export default LuoghiEn;



