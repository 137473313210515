import './olioList.css';
import {useEffect} from 'react';
import {Link} from "react-router-dom";

const OlioListEn = (props) => {
	
	return(
		<div className="olioList">
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>EVO OIL · 1L</h1>
				<h2>16,50€ (shipping excl.)</h2>
				<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20olive%20Oil" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">PURCHASE</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>EVO OIL · 3L</h1>
				<h2>47€ (shipping excl.)</h2>
				<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20olive%20Oil" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">PURCHASE</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>EVO OIL · 5L</h1>
				<h2>75€ (shipping excl.)</h2>
				<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20olive%20Oil" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">PURCHASE</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>EVO OIL · 10L</h1>
				<h2>150€ (shipping excl.)</h2>
				<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20olive%20Oil" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">PURCHASE</button>
				</Link>
			</div>
			</div>
		</div>
	)
}

export default OlioListEn;

