import '../bnb/bnb.css';
// import Navbar from '../../components/navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBuilding} from '@fortawesome/free-solid-svg-icons';
import WeareonEn from '../../components/WeareOn/Weareon_en';
import FooterEn from '../../components/footer/Footer_en';
import useFetch from "../../hooks/useFetch";
import {useState, useEffect} from 'react';
import {React} from 'react';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import {Link} from "react-router-dom";
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import {faSquareParking,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { GiTowel } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";

const DueEn = () => {

useEffect(() => {
window.scrollTo(0, 0);
  }, []);

  const { data, loading, error } = useFetch("/api/apartments/due/");

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;
    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? photos.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === photos.length - 1 ? 0 : slideNumber + 1;
    }
    setSlideNumber(newSlideNumber);
  };

  // Verifica se i dati stanno caricando
  if (loading) {
    return <div>Loading...</div>;
  }

  // Verifica se c'è un errore
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Verifica se i dati sono definiti e non sono vuoti
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Verifica la struttura dei dati
  console.log('Fetched data:', data);

  if (!data[0].availability) {
    return <div>No availability data</div>;
  }

  const ciao = data[0].availability;

  const renderCell = (date) => {
    const dateString = date.toISOString().slice(0, 10); // Ottieni la stringa della data (es. '2024-04-25')

    // Trova la disponibilità corrispondente alla data selezionata
    const dayAvailability = ciao.find(item => item.availableDate === dateString);

    if (!dayAvailability || !dayAvailability.available) {
      return <Badge className="calendar-unavailable-badge" />;
    }

    return <Badge content={`€${dayAvailability.price}`} />;
  };

	const photos = [
		{
			src: "https://i.imgur.com/b4RdwzR.jpeg"
		},

		{
			src: "https://i.imgur.com/GKjTe6J.jpeg"
		},

		{
			src: "https://i.imgur.com/zg1axhc.jpeg"
		},

		{
			src: "https://i.imgur.com/47IKsjV.jpeg"
		},

		{
			src: "https://i.imgur.com/FP6WGud.jpeg"
		}

	];

	return(
		<div>
			<Link to="/len=en/appartamenti" style={{color:"inherit", textDecoration:"none"}}>
			<section className="optionintro">
			<img src="https://i.imgur.com/BCfRaeg.png"/>
	    	</section>
	    	</Link>
			<div className="bContainer">
				{open && <div className="slider">
					<FontAwesomeIcon icon={faCircleXmark} className="close" onClick={()=>setOpen(false)}/>
					<FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={()=>handleMove("l")}/>
					<div className="sliderWrapper">
						<img src={photos[slideNumber].src} alt="" className="sliderImg" />
					</div>
					<FontAwesomeIcon icon={faCircleArrowRight} className="arrow" onClick={()=>handleMove("r")}/>
					</div>}
				<div className="bWrapper">
					<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20the%20apartment%20Two" style={{color:"inherit", textDecoration:"none"}}>
					<button className="bookNow">
						Book now!
					</button>
					</Link>
					<h1 className="siTitle"> TWO </h1>
					<span className="siSubtitle">
						Studio Apartment for 2 people
					</span>
					<span className="siCaratt">
						Kitchen/Bedroom ・ 1 Bathroom ・ 34sqm
					</span>
					<div className="bAddress">
						<Link to="https://maps.app.goo.gl/P2xzjzb56cSXy2Nx8?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
						<FontAwesomeIcon icon={faLocationDot} className="geodot"/>
						<span>Contrada Cerasa, 20/b, Vieste, 71019</span>
						</Link>
					</div>
{/*					<span className="bDistance">
						Excellent location - 500m from center
					</span>
					<span className="bPriceHighlight">
						Prenota e avrai gratis ...
					</span>*/}
					<div className="bImages">
						{photos.map((photo,i) =>(
							<div className="bImgWrapper">
								<img onClick={()=>handleOpen(i)} src={photo.src} alt="" className="bImg" />
							</div>
						))}
					</div>
					<div className="bDetails">
						<div className="bDetailsTexts">
							<h1 className="bTitle">Studio Apartment with Veranda</h1>
							<p className="bDesc">
								The Valle degli Ulivi apartments are located on the ground floor of a recently built building.<hr/>
								 Apartment Two is a 34sqm studio apartment, consisting of a large room with kitchen and double bed; and a private bathroom. It is the perfect solution for a couple looking for peace and relaxation.
								 The apartment is equipped with all comforts: Wi-Fi, kitchenette (equipped with pots and pans, crockery, coffee maker and everything else), private bathroom, flat screen TV, safe, hairdryer, toiletries (shampoo, etc.) and dishwashing liquid.<hr/>
								 Furthermore, all accommodations have an independent entrance and an outdoor veranda equipped with table and chairs for eating comfortably outdoors. <hr/>
								 Outside, available to guests: swimming pool with solarium area made up of sunbeds and deckchairs, a vast lawn, barbecue, external sinks and a children's playground.<hr/>
								 About two minutes away by car (about 1500 m) is the beach of St. Lorenzo, with very fine golden sand, while that of Pizzomunno is 2.1 km away. 
								 From the place it is possible to take relaxing walks in the greenery of the area or reach the Umbra Forest just 5-6 km away or reach a riding school just 2 km away for those who love horse riding. Green is the predominant color, there is no lack of silence to relax in complete tranquility. <hr/>
								 Michele and Leonardo, enthusiastic to host you, are always available to guests for any information on places to visit or products to taste. Don't hesitate to ask us where to find the best restaurants and farmhouses to taste typical local dishes or farms where you can taste and buy local cheeses and dairy products.
							</p>
							<h1 className="bTitle">Accommodation - To Know</h1>
							<p className="bDesc">
								Check-in is from 3pm to 6pm. Please kindly communicate your arrival time in advance. Unfortunately, it is not possible to bring forward check-in in any way.<hr/>
								 Check-out is from 7am to 10am. Please kindly respect the check-out time, in order to allow the rooms to be cleaned for subsequent guests.<hr/>
								 Guests are required to show photo identification upon check-in. Please note that Special Requests are subject to availability, and may incur a surcharge.<hr/>
								 Bed linen and towels are NOT included in the price. It is possible to arrive already equipped or rent it on site at the price of:
								 <ul>
									 <li>Towels: €5 per person, per stay</li>
									 <li>Bedroom: €5 per person, per stay</li>
								 </ul>
								 The swimming pool is open from 01/06 to 30/09, in the time slots: 9.30am - 12.30pm / 4.30pm - 7.30pm. <hr/>
								 Air conditioning is available, on request, at the price of €5 per day. <hr/>
								 Guests are kindly requested to bring a swimming cap to use the swimming pool. <hr/>
							</p>
						</div>
						<div className="bDetailsPrice">
							<h1>Perfect for 2 guests</h1>
							<span>
								Located 1500m from the sea and the center, we have a score of 8.4 on Booking.com!
							</span>
							<a href="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20further%20info%20about%20the%20apartment%20Two" class="text-decoration-none">
								<button className="linkbut">
									Book now!
								</button>
							</a>
						</div>
					</div>
					<div className="bServices">
						<div className="bServicesTexts">
							<h1 className="bTitle">Our Services</h1>
						</div>
						<div className="serviceList">
							<div className="serviceListItem">
								<TbAirConditioning className="bnbicon"/>
								<span>Air Conditioning (€)</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faSquareParking} className="bnbicon"/>
								<span>Internal Parking</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faWifi} className="bnbicon"/>
								<span>WiFi</span>
							</div>
							<div className="serviceListItem">
								<MdCoffeeMaker className="bnbicon"/>
								<span>Coffee Machine</span>
							</div>
							<div className="serviceListItem">
								<MdBalcony className="bnbicon"/>
								<span>Outdoor Veranda</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faTv} className="bnbicon"/>
								<span>TV</span>
							</div>
							<div className="serviceListItem">
								<GiTowel className="bnbicon"/>
								<span>Linen and towels (€)</span>
							</div>
							<div className="serviceListItem">
								<FaKitchenSet className="bnbicon"/>
								<span>Kitchen</span>
							</div>
							<div className="serviceListItem">
								<GiWashingMachine className="bnbicon"/>
								<span>Laundromat (€)</span>
							</div>
						</div>
						<div className="bServicesTexts">
							<h1 className="bTitle">Availability</h1>
							<span className="bspan">*Prices do not include the city tax, equal to €2.50 per person per night</span>
						</div>	
						<div className="cal">
							<Calendar
					            bordered
					            renderCell={renderCell}
					        />
			    		</div>					
					</div>
				</div>
			</div>
			<div className="lastitems">
				<WeareonEn />
				<FooterEn />
			</div>
		</div>
	);
}

export default DueEn;

