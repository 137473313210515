import './home.css';
import Header from '../../components/header/Header';
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';
import StrutturaAd from '../../components/StrutturaAd/StrutturaAd';
import OlioAd from '../../components/OlioAd/OlioAd';
import DisponibilitaAd from '../../components/DisponibilitàAd/DisponibilitaAd';
import ContattiAd from '../../components/ContattiAd/ContattiAd';
import BnbAd from '../../components/BnbAd/BnbAd';
import GuidaAd from '../../components/GuidaAd/GuidaAd';
import AppartamentiAd from '../../components/AppartamentiAd/AppartamentiAd';
import {useEffect} from 'react';
import {React} from 'react';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import { IT } from 'country-flag-icons/react/3x2';
import { GB } from 'country-flag-icons/react/3x2';
import {Link} from "react-router-dom";



const Home = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	const availability = {
    '2024-04-25': { available: true, price: 100 },
    '2024-04-30': { available: false },
    // Continua con gli altri giorni dell'anno
  	};

	  const renderCell = (date) => {
	    const dateString = date.toISOString().slice(0, 10); // Ottieni la stringa della data (es. '2024-04-25')
	    const dayAvailability = availability[dateString];

	    if (!dayAvailability || !dayAvailability.available) {
	      return <Badge className="calendar-unavailable-badge" />;
	    }

	    return <Badge content={`$${dayAvailability.price}`} />;
	  };

	  const handleDateSelect = (date) => {
	    // Implementa la gestione della selezione della data se necessario
	    // console.log('Data selezionata:', date);
	  };	

	return (
		<div>
			{/*<Navbar />*/}
			<section className="len">
	        	<IT title="Italy" className="flag"/>
	        	<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
	        	<GB title="Great Britain" className="flag"/>
	        	</Link>
			</section>
			<Header />
			<div className="homeContainer">
				{/*<Featured />*/}
				<section className="vdvTitle">
		        	<h1> VALLE DEGLI ULIVI B&B </h1>
		        	<p>Scopri le nostre camere</p>
	        	</section>
				<BnbAd />
				<section className="vdvTitle">
		        	<h1> VALLE DEGLI ULIVI APARTMENTS</h1>
		        	<p>Scopri i nostri appartamenti</p>
	        	</section>
				<AppartamentiAd />
				<section className="vdvTitle">
		        	<h1> HOMEPAGE </h1>
		        	<p>Scopri tutto ciò che Valle degli Ulivi ha da offire</p>
	        	</section>
	        	<h1 className="homeTit">LA STRUTTURA</h1>
				<StrutturaAd />
				<h1 className="homeTit">OLIO EXTRA VERGINE DI OLIVA</h1>
				<OlioAd />
				<h1 className="homeTitle">Vieste, una città da scoprire: la nostra guida!</h1>
				<GuidaAd />
				<h1 className="homeTit">LE NOSTRE DISPONIBILITA'</h1>
				<DisponibilitaAd />
				<h1 className="homeTit">CONTATTACI</h1>
				<ContattiAd />
				<Weareon />
				<Footer />
			</div>
		</div>
	);
}

export default Home;