import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import FooterOspiti from '../../components/footer/FooterOspiti';


const OspitiLuoghi = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/w5foY8C.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> LUOGHI D'INTERESSE </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/zmheTTY.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Centro Storico </h1>
						<span className="spiaggeFeatures">
							Il centro storico sorge compatto su un roccione dominante il mare.
							Il borgo antico, di origine medioevale, ha mantenuto quasi intatti i caratteri originari, con le sue caratteristiche viuzze irregolari, le piazzette/belvedere e le  case a schiera.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/xeXVFrWxW9p7qp9z5?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Vescovado%2016,%2071019%20Vieste%20FG,%20Italia&auid=7202429120802604223&ll=41.881434,16.180171&lsp=9902&q=Cattedrale%20di%20Santa%20Maria%20Assunta&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/mOYQDZV.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Castello Svevo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							Il Castello di Vieste, che sovrasta con la sua imponente mole il centro storico, risale alla II metà del sec. XI, quando conte di Vieste era il normanno Roberto Drengot.
							E' a pianta triangolare, corredata agli spigoli di tre bastioni a punta di lancia, che incorporano quelli più antichi a base circolare ed è una strutture con funzione difensiva.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/Lwd6oHX36qWvQtSk6?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Largo%20Castello%2011,%2071019%20Vieste%20FG,%20Italia&auid=18314850826097158027&ll=41.880961,16.179229&lsp=9902&q=Castello%20Svevo%20Aragonese&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/0sew6NB.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Trabucchi </h1>
						<span className="spiaggeFeatures">
							I Trabucchi sono antiche macchine da pesca risalenti al XIX secolo, secondo gli storici è un’invenzione del popolo fenicio.
							Famosi sono quelli di "Punta San Francesco", "baia San Lorenzo", "Scialmarino","Molinella".
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni ("Scialmarino"):
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/s96bXKPh3cquEbmeA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=5090710702777020403&ll=41.914264,16.134902&lsp=9902&q=Il%20Trabucco%20di%20Scialmarino&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/fOVnQlp.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Scalinata Amore </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							La scalinata dell’amore è stata realizzata in occasione della 1° Vieste in Love, 
							dipingendo una dalle scale che collegano la porta alta della città vecchia con il corso Fazzini.
							Sui gradini di questa singolare scala è riportato il testo della Canzone “La Leggenda di Pizzomunno e Cristalda” di Max Gazzè, che racconta la leggenda di Pizzomunno e Cristalda.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/W1F2KCy5uYNeykuTA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Deputato%20Petrone%2013,%2071019%20Vieste%20FG,%20Italia&auid=15642170791326721239&ll=41.882501,16.180143&lsp=9902&q=Scalinata%20dell%E2%80%98%20Amore&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/0RwBl5N.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Pizzomunno </h1>
						<span className="spiaggeFeatures">
							Il Pizzomunno è un imponente monolite in pietra calcarea alto 25 metri. 
							E’ situato all’inizio della spiaggia a sud del centro abitato, detta “del Castello” o “Scialara” o “del Pizzomunno”. 
							Per la sua imponenza sembra ergersi quasi a guardia di Vieste e per il suo fascino è diventato il simbolo stesso della cittadina garganica. 
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/1RQNQEP7KKVLvmou8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/J7I7ltZ.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Faro S.Eufemia </h1>
						<span className="spiaggeFeatures">
							Il Faro di Vieste, ammirabile da gran parte della città, sorge sull’isolotto di Santa Eufemia, situato tra punta Santa Croce e punta San Francesco.
							La sua posizione risulta strategica per le rotte di navigazione tra il medio e basso Adriatico. È stato progettato nel 1867 e ad oggi risulta disabitato a seguito dell'automazione del sistema di illuminazione.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/RBCJEq8Dp9Xw2kTu8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Vieste%20FG,%20Italia&auid=6195786736746575652&ll=41.889177,16.184292&lsp=9902&q=Faro%20di%20Vieste&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/Gzxxt0v.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Foresta Umbra </h1>
						<span className="spiaggeFeatures">
							La riserva naturale Foresta Umbra è un’area naturale protetta che si trova nella parte più interna del Parco Nazionale del Gargano. 
							Deve il suo nome alla fitta vegetazione che la rende molto ombrosa per molti tratti.
							La foresta umbra ha una superficie di circa 10.000 ettari e presenta una geografia accidentata.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/KbhJptJ6m7BU93er8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=SP%2052%20bis,%2071018%20Vico%20del%20Gargano%20Puglia,%20Italia&ll=41.870098,16.052626&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">NON DIMENTICARE DI ACQUISTARE IL NOSTRO OLIO EVO!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspiti />
		</div>
	);
}

export default OspitiLuoghi;



