import "./disponibilitaAd.css";
import {Link} from "react-router-dom";

const DisponibilitaAdEn = () => {
	return(
		<div className="disponibilitaAd">
			<Link to="/len=en/disponibilita" style={{color:"inherit", textDecoration:"none"}}>
			<div className="disponibilitaItem">
				<img src="https://i.imgur.com/JVY6ULw.jpeg" className="disponibilitaImg" />
			</div>
			</Link>
		</div>
	);
}

export default DisponibilitaAdEn;