import "./guidaAd.css";
import {Link} from "react-router-dom";

const GuidaAdEn = () => {
	return(
		<div className="strutturaAd">
			<Link to="/len=en/guida" style={{color:"inherit", textDecoration:"none"}}>
			<div className="olioItem">
				<img src="https://i.imgur.com/CppsFX6.jpeg" className="strutturaImg" />
			</div>
			</Link>
		</div>
	);
}

export default GuidaAdEn;