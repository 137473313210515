import {React, useEffect} from 'react';
import './calendario.css';
import {Link} from "react-router-dom";
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';

const Calendario = () => {

  useEffect(()=>{
  	window.scrollTo(0,0);
  },[])

  return (
    <div className="sections">
      <Link to="/" style={{color:"inherit", textDecoration:"none"}}>
      <section className="intro">
        <img src="https://i.imgur.com/JVY6ULw.jpeg" alt="" className="vdvImg" />
      </section>
      </Link>
      <section className="vdvTitle">
        <h1> DISPONIBILITA' CAMERE B&B </h1>
        <p>Scorri per scoprire le nostre disponibilità</p>
      </section>
      <div className="dispbnbAd">
      	<Link to="/bnbs/grecale" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/m1eucd6.jpeg" className="dispbnbAdImg" />
		  		<div className="dispbnbAdTitles">
						<h1>
							<b>GRECALE</b>				
						</h1>
		  		</div>
		  	</div>
		  	</Link>
			  <Link to="/bnbs/scirocco" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/TxQT3x8.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>SCIROCCO</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/bnbs/libeccio" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/ULEeei0.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>LIBECCIO</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/bnbs/maestrale" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/BJC0wn5.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>MAESTRALE</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
	  </div>
	  <section className="vdvTitle">
        <h1> DISPONIBILITA' APPARTAMENTI </h1>
        <p>Scorri per scoprire le nostre disponibilità</p>
      </section>
      <div className="dispbnbAd">
      	<Link to="/appartamenti/uno" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/AWVYfuI.jpeg" className="dispbnbAdImg" />
		  			<div className="dispappAdTitles">
							<h1>
								<b>UNO</b>				
							</h1>
		  			</div>
		  	</div>
		  	</Link>
		  	<Link to="/appartamenti/due" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/xHfvG17.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>DUE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/appartamenti/tre" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/3Egc8G7.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>TRE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/appartamenti/quattro" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/qftn8d0.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>QUATTRO</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/appartamenti/cinque" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/07ZOUZT.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>CINQUE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/appartamenti/sei" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/TXO95fC.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>SEI</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/appartamenti/sette" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/l5usDiZ.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>SETTE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
	  	</div>
	  <Weareon />
	  <Footer />
    </div>
  );
};

export default Calendario;
