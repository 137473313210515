import {React,useEffect} from 'react';
import './vdv.css';
import {Link} from "react-router-dom";
import { GiOlive } from "react-icons/gi";
import { FaOilCan } from "react-icons/fa";
import { LiaShippingFastSolid } from "react-icons/lia";
import Footer from '../../components/footer/Footer';


const VdV = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI </h1>
	        	<p>Scopri la nostra meravigliosa struttura</p>
	        </section>
	        <div className="vdvImages">
	        	<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/SQFBifW.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">STRUTTURA</h1>
							<p className="midDesc">La struttura ricettiva Valle degli Ulivi
								nasce nel 2010, ma ha subito diverse opere di rinnovamento negli anni.
								Al piano terra si trovano gli appartamenti, mentre al secondo (e ultimo) piano 
								le camere del B&B. <hr/>
								La struttura è inoltre circondata da 2 ettari di uliveto secolare: di certo
								lo spazio non manca!
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/YzO1oHb.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">PISCINA</h1>
							<p className="midDesc">Realizzata nel 2024, 
								la nostra piscina vi accompagnerà durante tutto il vostro soggiorno,
								garantendovi il benessere ed il relax di cui avete bisogno.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/j3rLWJg.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">SOLARIUM</h1>
							<p className="midDesc">L'area Solarium è pensata per chi 
								vuole godersi del meritato riposo in mezzo al verde del nostro prato,
								o per chi vuole semplicemente abbronzarsi lontano dalle spiagge affollate.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/zluV65F.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">BARBECUE</h1>
							<p className="midDesc">Valle degli Ulivi mette a disposizione dei propri
								ospiti un barbecue in pietra lavica, su cui è possibile cucinare
								dell'ottimo pesce (o carne) locale.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/xJQg4uo.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">PARCHEGGIO</h1>
							<p className="midDesc">Valle degli Ulivi mette inoltre a disposizione dei propri
								ospiti un parcheggio interno custodito e recintato, sottoposto a sorveglianza 24h.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/bukprWI.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">PARCO GIOCHI</h1>
							<p className="midDesc">Per la gioia dei figli (e forse anche dei genitori),
								Valle degli Ulivi dispone di un parco giochi per bambini, in cui potranno 
								svagarsi e divertirsi senza freni.
							</p>		
						</div>
				</div>
			</div>
			<Link to="/olio" style={{color:"inherit", textDecoration:"none"}}>
			<div className="mail">
			<div className="elements">
				<h1 className="mailTitle">Scopri il nostro Olio EVO</h1>
				<div className="brandicons">
					<GiOlive className="oilicon"/>
					<FaOilCan className="oilicon"/>
					<LiaShippingFastSolid className="oilicon"/>
				</div>
			</div>
			</div>
			</Link>
			<Footer />
 		</div>
	);
}

export default VdV;



