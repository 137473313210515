import "./footer.css";
import {Link} from "react-router-dom";

const FooterOspitiEn = () => {
	return(
		<div className="footer">		
			<div className="fLists">
				<ul className="fList">
					<Link to="/len=en/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Guide</li>
					</Link>
					<Link to="/len=en/ospiti/guida/spiagge" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Beaches</li>
					</Link>
					<Link to="/len=en/ospiti/guida/escursioni-e-tour" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Excursions and Tours</li>
					</Link>
					<Link to="/len=en/ospiti/guida/luoghi-di-interesse" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Interesting Sites</li>
					</Link>
					<Link to="/len=en/ospiti/guida/ristoranti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Restaurants</li>
					</Link>
					<Link to="/len=en/ospiti/guida/parcheggio" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Parking</li>
					</Link>
				</ul>
			</div>
			<div className="fText">Copyright © 2010–2024 valledegliulivivieste.com™. Tutti i diritti riservati.</div>
		</div>
	);
}

export default FooterOspitiEn;