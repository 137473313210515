import "./weareon.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faWhatsapp, faAirbnb } from '@fortawesome/free-brands-svg-icons';
import { TbBrandBooking } from "react-icons/tb";

const WeareonEn = () => {
	return(
		<div className="mail">
			<div className="elements">
				<h1 className="mailTitle">You can find us on...</h1>
				<div className="brandicons">
					<FontAwesomeIcon icon={faGoogle} className="brandicon" />
					<FontAwesomeIcon icon={faWhatsapp} className="brandicon" />
					<FontAwesomeIcon icon={faAirbnb} className="brandicon" />
					<TbBrandBooking className="booking"/>
				</div>
{/*				<span className="mailDesc">Sign up and we'll send the best</span>
				<div className="mailInputContainer">
					<input type="text" placeholder="Your Email" />
					<button>Subscribe</button>
				</div>*/}
			</div>
		</div>
	);
}

export default WeareonEn;
