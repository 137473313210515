import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import FooterOspiti from '../../components/footer/FooterOspiti';


const Ristoranti = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/kpJjVje.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> RISTORANTI </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/qUaPSDF.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> La Galea </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ristorante in centro
						</span>
						<span className="spiaggeFeatures">
							La Galea è la prima scelta consigliata da Valle degli Ulivi
							per poter degustare i piatti che contraddistinguono la cucina a base di pesce.
							Contattando noi di Valle degli Ulivi, ci occuperemo in prima persona della prenotazione.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/apWgGustVrHXJ7Ju5?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Santa%20Maria%20di%20Merino%2056,%2071019%20Vieste%20FG,%20Italia&auid=497574204647373206&ll=41.883674,16.176870&lsp=9902&q=Ristorante%20La%20Galea&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/4dwoSvW.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Osteria al Duomo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ristorante in centro
						</span>
						<span className="spiaggeFeatures">
							L'Osteria al Duomo è uno dei ristoranti più rinomati a livello locale,
							complice la location immersa nel centro storico.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/2DwACdRYZjqmYVJMA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/bxOdi3a.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Le Tre Vele </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ristorante sul mare
						</span>
						<span className="spiaggeFeatures">
							Le Tre Vele è un suggestivo ristorante situato sul litorale Sud viestano,
							con una location a due passi dal mare.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/YuZHL1GQkNhofaPc9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Vignanotica,%2071019%20Vieste%20FG,%20Italia&auid=4252621985428501806&ll=41.761233,16.164810&lsp=9902&q=Spiaggia%20Di%20Vignanotica&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/FJcVISZ.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lido San Lorenzo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ristorante sul mare
						</span>
						<span className="spiaggeFeatures">
							Lido San Lorenzo prende il nome dall'omonima baia, caratterizzato da una struttura in legno
							che affaccia direttamente sul mare.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/AgWxfVKFGBaNdpiQ9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=8801275055752316386&ll=41.815425,16.194729&lsp=9902&q=Camping%20Calacampi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/2JddpdT.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Chalet degli Ulivi </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ristorante immerso nel verde
						</span>
						<span className="spiaggeFeatures">
							Chalet degli Ulivi è un ristorante situato fuori dal centro abitato, immerso
							in un uliveto secolare, in cui poter degustare sia tagli di carne locale che ottime pizze.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/bYpvJD51aaaYDoNG7?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Marchionna%203,%2071019%20Vieste%20FG,%20Italia&auid=16384565245281265395&ll=41.860205,16.160674&lsp=9902&q=Chalet%20degli%20ulivi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">NON DIMENTICARE DI ACQUISTARE IL NOSTRO OLIO EVO!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspiti />
		</div>
	);
}

export default Ristoranti;



