import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import FooterOspitiEn from '../../components/footer/FooterOspitiEn';


const ParcheggioEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/me7qkqJ.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> PARKING AREAS </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/WLRJMAk.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tourist Pier </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							Located near the new tourist pier, this huge parking area is perfect for those who want to reach the center, 
							just a few hundred meters away.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/yys2NR6Az7fxJuWN9?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?ll=41.885089,16.175079&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/ysUs6sT.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Parking Europe </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							Located close to the Europa seafront, 
							this parking area is perfect for those who want to quickly reach the beach or the center.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/pffY24JFsEZgZn3u8?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?ll=41.886172,16.167375&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">DO NOT FORGET TO PURCHASE OUR OLIVE OIL!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspitiEn />
		</div>
	);
}

export default ParcheggioEn;



