import "./footer.css";
import {Link} from "react-router-dom";

const Footer = () => {
	return(
		<div className="footer">
			<div className="fLists">
				<ul className="fList">
					<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Home</li>
					</Link>
					<Link to="/len=en/contatti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Contact us</li>
					</Link>
					<Link to="/len=en/disponibilita" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Availability</li>
					</Link>
					<Link to="/len=en/guida" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Our Guide</li>
					</Link>
					<Link to="/len=en/bnbs" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">B&B</li>
					</Link>
					<Link to="/len=en/appartamenti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Apartments</li>
					</Link>
					<Link to="/len=en/olio" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Extra Vergin Olive Oil</li>
					</Link>
					<Link to="/len=en/vdv" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">The Location</li>
					</Link>
				</ul>
			</div>
			<div className="fText">Copyright © 2010–2024 valledegliulivivieste.com™. All rights reserved.</div>
		</div>
	);
}

export default Footer;