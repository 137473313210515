import {React, useEffect} from 'react';
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './contatti.css';

const Contatti = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/sZC9PAR.jpeg" alt="" className="vdvImg"/>
	        </section>
	        </Link>
	        <section className="contattisection">
	        	<div className="contattiTitle">
		        	<h1> VALLE DEGLI ULIVI B&B </h1>
		        	<h2>LEONARDO</h2>
		        	<p>+39 3409494027</p>
		        	<p>valledegliulivivieste@gmail.com</p>
		        	<p>Contrada Cerasa, 20/b, 71019, Vieste, FG</p>
		        	<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sul%20B&B" style={{color:"inherit", textDecoration:"none"}}>
		        	<FontAwesomeIcon icon={faWhatsapp} className="contatticon" />
		        	</Link>
	        	</div>
	        	<div className="contattiTitle">
		        	<h1> VALLE DEGLI ULIVI APARTMENTS </h1>
		        	<h2>MICHELE</h2>
		        	<p>+39 3475952559</p>
		        	<p>valledegliulivivieste@gmail.com</p>
		        	<p>Contrada Cerasa, 20/b, 71019, Vieste, FG</p>
		        	<Link to="https://wa.me/3475952559?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sugli%20Appartamenti" style={{color:"inherit", textDecoration:"none"}}>
		        	<FontAwesomeIcon icon={faWhatsapp} className="contatticon" />
		        	</Link>
	        	</div>
	        </section>
	        <section className="contattisection">
	        	<div className="contattiTitle">
		        	<h1> OLIO EXTRA VERGINE DI OLIVA </h1>
		        	<h2>LEONARDO</h2>
		        	<p>+39 3409494027</p>
		        	<p>valledegliulivivieste@gmail.com</p>
		        	<p>Contrada Cerasa, 20/b, 71019, Vieste, FG</p>
		        	<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20Olio" style={{color:"inherit", textDecoration:"none"}}>
		        	<FontAwesomeIcon icon={faWhatsapp} className="contatticon" />
		        	</Link>
	        	</div>
	        </section>
	        <Weareon />
	        <Footer />
		</div>
	);
}

export default Contatti;