import "./strutturaAd.css";
import {Link} from "react-router-dom";

const StrutturaAd = () => {

	return(
		<div className="strutturaAd">
			<Link to="/vdv" style={{color:"inherit", textDecoration:"none"}}>
			<div className="strutturaItem">
				<img src="https://i.imgur.com/IZCPoRT.jpeg" className="strutturaImg" />
			</div>
			</Link>
		</div>
	);
}

export default StrutturaAd;