import {React, useEffect} from 'react';
import './olio.css';
import OlioListEn from './OlioList_en';
import WeareonEn from '../../components/WeareOn/Weareon_en';
import FooterEn from '../../components/footer/Footer_en';
import {Link} from "react-router-dom";
import { LiaShippingFastSolid } from "react-icons/lia";


const OlioEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/HpCiLqc.jpeg" alt="" className="vdvImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> EXTRA VIRGIN OLIVE OIL </h1>
	        	<p>Scroll for more details and purchase it!</p>
	        </section>
	        <section className="bottle">
				<img src="https://i.imgur.com/8cpwbuV.png" alt="" className="olioImg"/>
				<div className="olioDesc">
					<table width="600" border="0">
					<tr>
					<td width="50%"><b>PRODUCER</b></td>
					<td width="50%">"Valle degli Ulivi" Agr. Company</td>
					</tr>
					<tr>
					<td width="50%"><b>LOCATION</b></td>
					<td width="50%">
						Company center immersed in slightly hilly centuries-old olive groves, 
						in the heart of the Gargano National Park
					</td>
					</tr>
					<tr>
					<td width="50%"><b>PRODUCTION CULTIVAR</b></td>
					<td width="50%">Ogliarola Garganica 100%</td>
					</tr>
					<tr>
					<td width="50%"><b>HARVESTING</b></td>
					<td width="50%">
						The harvest period begins in October, 
						in the full veraison phase (the olive takes on a purplish color)
					</td>
					</tr>
					<tr>
					<td width="50%"><b>COLOR</b></td>
					<td width="50%">Golden yellow, medium density</td>
					</tr>
					<tr>
					<td width="50%"><b>EXTRACTION METHOD</b></td>
					<td width="50%">
						Sinocea, completely cold extraction 
						with temperatures that do not exceed 25 degrees 
						throughout the entire process
					</td>
					</tr>
					<tr>
					<td width="50%"><b>ACIDITY</b></td>
					<td width="50%">From 0.2% to 0.35% oleic acid</td>
					</tr>
					</table>
				</div>
	        </section>
	        <section className="vdvTitle">
	        	<h1> CHARACTERISTICS AND PROPERTIES OF THE OLIVE OIL</h1>
	        </section>
	        <section className="bottle">
	        	<img src="https://i.imgur.com/V78Kf78.jpeg" alt="" className="evoImg"/>
				<div className="olioDesc">
					<p className="oliocar">
						Extra Virgin Olive Oil is a food rich in antioxidants: one above all oleic acid. The latter, together with linoleic acid and polyphenols, are real anti-tumor substances. <hr/>
						Also linolenic acid (more commonly known as
						Omega 3) constitutes an essential element of extra virgin olive oil as it is the element that keeps the 
						level of cholesterol in the blood low. <hr/>
						Extra virgin olive oil is an excellent alternative (and certainly 
						healthier) with butter and lard. <hr/>
						Ultimately, our oil, with its intense fruity and almond fragrance, is a guarantee of quality.
					</p>
				</div>
			</section>
	        <section className="vdvTitle">
	        	<h1> OUR PRODUCTS </h1>
	        	<p>Choose the quantity and buy it now!</p>
	        </section>
	        <OlioListEn image = "https://i.imgur.com/8cpwbuV.png" />
	        <div className="mail">
				<div className="elements">
					<h1 className="mailTitle">SHIPPING THROUGHOUT ITALY AND ABROAD</h1>
					<div className="brandicons">
						<LiaShippingFastSolid className="shipping"/>
					</div>
	{/*				<span className="mailDesc">Sign up and we'll send the best</span>
					<div className="mailInputContainer">
						<input type="text" placeholder="Your Email" />
						<button>Subscribe</button>
					</div>*/}
				</div>
			</div>
			<FooterEn />
	    </div>
	);
}

export default OlioEn;


