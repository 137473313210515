import {React, useEffect} from 'react';
import './guida.css';
import {Link} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Weareon from '../../components/WeareOn/Weareon';
import FooterEn from '../../components/footer/Footer_en';

const GuidaEn = () => {

  useEffect(()=>{
  	window.scrollTo(0,0);
  },[])

  return (
    <div className="sections">
      <Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
      <section className="intro">
        <img src="https://i.imgur.com/tmp4p3g.jpeg" alt="" className="vdvImg" />
      </section>
      </Link>
      <section className="vdvTitle">
      	<h1> OUR GUIDE </h1>
      	<p>Scroll to discover the wonders of Vieste!</p>
      </section>
      <div className="guida">
      	<Link to="/len=en/guida/spiagge" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="guidaItem">
				<img src="https://i.imgur.com/zyfnf56.jpeg" className="guidaImg" />
		  		<div className="guidaTitles">
					<h1>
						<b>BEACHES</b>				
					</h1>
		  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/guida/escursioni-e-tour" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/qwVMzHV.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>EXCURSIONS AND TOURS</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/guida/luoghi-di-interesse" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/w5foY8C.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>INTERESTING SITES</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
	  	</div>
	  	<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<FooterEn />
	  </div>
	);
};

export default GuidaEn;









