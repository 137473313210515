import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import Footer from '../../components/footer/Footer';

const Spiagge = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/zyfnf56.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> SPIAGGE </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/r9Fnqzh.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lungomare Europa </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in sabbia
						</span>
						<span className="spiaggeFeatures">
							Si tratta del litorale Nord, nonchè il più vicino alla nostra struttura. Si estende per circa 3 km.
							Proseguendo verso nord, si raggiunge il lungomare di "Porticello", per poi terminare a Peschici.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/vzkCALGUEWhZVGEy6?g_st=in&_imcp=1" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Europa,%2071019%20Vieste%20FG,%20Italia&ll=41.885136,16.172717&q=Lungomare%20Europa&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/zjQV66R.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lungomare Mattei </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in sabbia
						</span>
						<span className="spiaggeFeatures">
							Si tratta del litorale Sud. Si estende per circa 4 km.
							Proseguendo verso sud, si possono raggiungere fantastiche località come Mattinata e Manfredonia.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/2DwACdRYZjqmYVJMA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/QSMrjrd.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Baia di Vignanotica </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in ciottoli
						</span>
						<span className="spiaggeFeatures">
							Si tratta di una splendida spiaggia in ciottoli, lunga circa 500 metri, 
							caratterizzata dalla presenza di molteplici grotte scavate dal mare e dalla alta e bianca falesia calcarea alle sue spalle, molto ripida.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/YuZHL1GQkNhofaPc9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Vignanotica,%2071019%20Vieste%20FG,%20Italia&auid=4252621985428501806&ll=41.761233,16.164810&lsp=9902&q=Spiaggia%20Di%20Vignanotica&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/WCTpygR.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Baia di Campi </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in ciottoli
						</span>
						<span className="spiaggeFeatures">
							Si trova a circa 10 km a sud Vieste ed è circondata da un bellissimo paesaggio mediterraneo con pini d’aleppo.
							Qui troviamo una bella spiaggia di ghiaia lunga 500 metri, dal fondale profondo, mentre al centro della baia sono ospitati anche  due isolotti.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/AgWxfVKFGBaNdpiQ9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=8801275055752316386&ll=41.815425,16.194729&lsp=9902&q=Camping%20Calacampi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/G40vYEI.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Portogreco </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in ciottoli
						</span>
						<span className="spiaggeFeatures">
							La Baia di Portogreco è un piccolo “porto” naturale immerso nella verdeggiante macchia mediterranea.
							La spiaggia, larga 84 metri, ha fondale alto.
							Via mare è possibile raggiungere anche l’ Arco naturale di Porto Greco, tipico architello della costa garganica.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/c77cetwNPxRqad6V8?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Porto%20Greco,%2071019%20Vieste%20FG,%20Italia&auid=12328222123947254497&ll=41.800019,16.192678&lsp=9902&q=Spiaggia%20di%20Porto%20Greco&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/7oe3Lfk.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Gattarella </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in sabbia
						</span>
						<span className="spiaggeFeatures">
							La Spiaggia della Gattarella,lunga 400 metri, è situata a sud di Vieste. 
							La spiaggia deve il nome alla storica torre saracena presente.
							Quando la marea è bassa e il mare calmo o poco mosso, l'isolotto diventa meta di romantiche escursioni a nuoto o in pedalò.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/9M2c9PonLvSVkK3s7?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%20179%E2%80%93181,%2071019%20Vieste,%20Italia&auid=16542512693579070607&ll=41.840900,16.184300&lsp=9902&q=Canale%20Porto%20Nuovo-%20Corr.%20Foce&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/2zakSGu.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Scialmarino </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Spiaggia in sabbia
						</span>
						<span className="spiaggeFeatures">
							La Spiaggia di Scialmarino si trova a circa 4 km da Vieste sulla litorea in direzione Peschici. 
							E’ una spiaggia di sabbia dorata, lunga quasi 3 chilometri, con il fondale che degrada dolcemente. La sua baia è molto suggestiva e offre anche la vista di un trabucco.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/ze98ty3X3Y2qwgUj7?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=9433542839651370045&ll=41.912366,16.132759&lsp=9902&q=Tra%20Sp.scialmarino%20E%20T.re%20Porticello&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<Footer />
		</div>
	);
}

export default Spiagge;



