import "./footer.css";
import {Link} from "react-router-dom";

const FooterOspiti = () => {
	return(
		<div className="footer">
			<div className="fLists">
				<ul className="fList">
					<Link to="/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Guida</li>
					</Link>
					<Link to="/ospiti/guida/spiagge" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Spiagge</li>
					</Link>
					<Link to="/ospiti/guida/escursioni-e-tour" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Escursioni e Tour</li>
					</Link>
					<Link to="/ospiti/luoghi-di-interesse" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Luoghi d'interesse</li>
					</Link>
					<Link to="/ospiti/guida/ristoranti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Ristoranti</li>
					</Link>
					<Link to="/ospiti/guida/parcheggio" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Dove Parcheggiare</li>
					</Link>
				</ul>
			</div>
			<div className="fText">Copyright © 2010–2024 valledegliulivivieste.com™. Tutti i diritti riservati.</div>
		</div>
	);
}

export default FooterOspiti;