import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import FooterOspitiEn from '../../components/footer/FooterOspitiEn';


const RistorantiEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/kpJjVje.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> RESTAURANTS </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/qUaPSDF.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> La Galea </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Restaurant in the centre
						</span>
						<span className="spiaggeFeatures">
							La Galea is the first choice recommended by Valle degli Ulivi to be able to taste the dishes that distinguish fish-based cuisine. 
							By contacting us at Valle degli Ulivi, we will personally take care of the booking.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/apWgGustVrHXJ7Ju5?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Via%20Santa%20Maria%20di%20Merino%2056,%2071019%20Vieste%20FG,%20Italia&auid=497574204647373206&ll=41.883674,16.176870&lsp=9902&q=Ristorante%20La%20Galea&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/4dwoSvW.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Osteria al Duomo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Restaurant in the Old Town
						</span>
						<span className="spiaggeFeatures">
							Osteria al Duomo is one of the most renowned restaurants locally, 
							thanks to the location immersed in the historic center.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/2DwACdRYZjqmYVJMA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Enrico%20Mattei%2030%E2%80%9332,%2071019%20Vieste%20FG,%20Italia&auid=10546866303131045380&ll=41.877910,16.177028&lsp=9902&q=Spiaggia%20di%20Pizzomunno&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/bxOdi3a.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Le Tre Vele </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Seaside restaurant
						</span>
						<span className="spiaggeFeatures">
							Le Tre Vele is a charming restaurant located on the southern coast of Vieste, 
							with a location a stone's throw from the sea.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/YuZHL1GQkNhofaPc9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Spiaggia%20di%20Vignanotica,%2071019%20Vieste%20FG,%20Italia&auid=4252621985428501806&ll=41.761233,16.164810&lsp=9902&q=Spiaggia%20Di%20Vignanotica&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/FJcVISZ.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Lido San Lorenzo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Seaside restaurant
						</span>
						<span className="spiaggeFeatures">
							Lido San Lorenzo takes its name from the bay of the same name, 
							characterized by a wooden structure that directly overlooks the sea.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/AgWxfVKFGBaNdpiQ9?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?auid=8801275055752316386&ll=41.815425,16.194729&lsp=9902&q=Camping%20Calacampi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/2JddpdT.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Chalet degli Ulivi </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Restaurant surrounded by greenery
						</span>
						<span className="spiaggeFeatures">
							Chalet degli Ulivi is a restaurant located outside the town centre, 
							immersed in a centuries-old olive grove, 
							where you can taste both cuts of local meat and excellent pizzas.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/bYpvJD51aaaYDoNG7?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Marchionna%203,%2071019%20Vieste%20FG,%20Italia&auid=16384565245281265395&ll=41.860205,16.160674&lsp=9902&q=Chalet%20degli%20ulivi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">DO NOT FORGET TO PURCHASE OUR OLIVE OIL!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspitiEn />
		</div>
	);
}

export default RistorantiEn;



