import {React, useEffect} from 'react';
import '../guida/guida.css';
import {Link} from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { IT } from 'country-flag-icons/react/3x2';
import { GB } from 'country-flag-icons/react/3x2';
import Weareon from '../../components/WeareOn/Weareon';
import FooterOspiti from '../../components/footer/FooterOspiti';


const OspitiGuida = () => {

  useEffect(()=>{
  	window.scrollTo(0,0);
  },[])

  return (
    <div className="sections">
    	<section className="len">
      	<IT title="Italy" className="flag"/>
      	<Link to="/len=en/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
      	<GB title="Great Britain" className="flag"/>
      	</Link>
			</section>
      <section className="intro">
        <img src="https://i.imgur.com/tmp4p3g.jpeg" alt="" className="vdvImg" />
      </section>
      <section className="vdvTitle">
      	<h1> LA NOSTRA GUIDA </h1>
      	<p>Scorri per scoprire le meraviglie del territorio!</p>
      </section>
      <div className="guida">
      	<Link to="/ospiti/guida/spiagge" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="guidaItem">
				<img src="https://i.imgur.com/zyfnf56.jpeg" className="guidaImg" />
		  		<div className="guidaTitles">
					<h1>
						<b>SPIAGGE</b>				
					</h1>
		  		</div>
			  </div>
			  </Link>
			  <Link to="/ospiti/guida/escursioni-e-tour" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/qwVMzHV.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>ESCURSIONI E TOUR</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/ospiti/guida/luoghi-di-interesse" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/w5foY8C.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>LUOGHI D'INTERESSE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/ospiti/guida/ristoranti" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/kpJjVje.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>RISTORANTI</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/ospiti/guida/parcheggio" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="guidaItem">
					<img src="https://i.imgur.com/me7qkqJ.jpeg" className="guidaImg" />
			  		<div className="guidaTitles">
						<h1>
							<b>DOVE PARCHEGGIARE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
	  	</div>
	  	<div className="oliopub">
		  	<h1 className="oliopubTit">NON DIMENTICARE DI ACQUISTARE IL NOSTRO OLIO EVO!</h1>
		  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  	</div>
	  	<FooterOspiti />
	  </div>
	);
};

export default OspitiGuida;









