import './olioList.css';
import {useEffect} from 'react';
import {Link} from "react-router-dom";

const OlioList = (props) => {
	
	return(
		<div className="olioList">
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>OLIO EVO · 1L</h1>
				<h2>16,50€ (escl. spedizione)</h2>
				<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20Olio" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">ACQUISTA</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>OLIO EVO · 3L</h1>
				<h2>47€ (escl. spedizione)</h2>
				<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20Olio" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">ACQUISTA</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>OLIO EVO · 5L</h1>
				<h2>75€ (escl. spedizione)</h2>
				<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20Olio" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">ACQUISTA</button>
				</Link>
			</div>
			</div>
			<div className="olioListItem">
				<img src={props.image} className="olioListImg" />
			<div className="olioListTitles">
				<h1>OLIO EVO · 10L</h1>
				<h2>150€ (escl. spedizione)</h2>
				<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20Olio" style={{color:"inherit", textDecoration:"none"}}>
				<button class="oliobutton">ACQUISTA</button>
				</Link>
			</div>
			</div>
		</div>
	)
}

export default OlioList;

