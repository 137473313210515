import "./bnbAd.css";
import {Link} from "react-router-dom";


const BnbAdEn = () => {
	return(
		<div className="bnbAd">
			<Link to="/len=en/bnbs" style={{color:"inherit", textDecoration:"none"}}>
			<div className="bnbAdItem">
				<img src="https://i.imgur.com/0zuSija.jpeg" className="bnbAdImg" />
			</div>
			</Link>
		</div>
	);
}

export default BnbAdEn;