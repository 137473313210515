import './list.css';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import { GiTowel } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";
import Footer from '../../components/footer/Footer';

const AppList = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	// const location = useLocation();
	// const [destination, setDestination] = useState(location.state.destination)
	// const [date, setDate] = useState(location.state.date)
	// const [openDate, setOpenDate] = useState(false)
	// const [options, setOptions] = useState(location.state.options)

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI APPARTAMENTI </h1>
	        	<p>Scopri i nostri appartamenti</p>
	        </section>
			<div className="listContainer">
					<div className="listResult">
						<div className="searchItem"> 
							<Link to="/appartamenti/uno" style={{color:"inherit", textDecoration:"none"}}>
							<img src="https://i.imgur.com/AWVYfuI.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> UNO </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Trilocale per 4-6 persone
								</span>
								<span className="siFeatures">
									Cucina ・ 2 Camere ・ 1 bagno ・ 55mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/due" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/xHfvG17.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> DUE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Monolocale per 2 persone
								</span>
								<span className="siFeatures">
									Cucina/Camera ・ 1 bagno ・ 34mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/tre" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/3Egc8G7.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> TRE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Monolocale per 2 persone
								</span>
								<span className="siFeatures">
									Cucina/Camera ・ 1 bagno ・ 35mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/quattro" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/qftn8d0.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> QUATTRO </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Monolocale per 2 persone
								</span>
								<span className="siFeatures">
									Cucina/Camera ・ 1 bagno ・ 36mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/cinque" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/07ZOUZT.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> CINQUE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Bilocale per 2 persone
								</span>
								<span className="siFeatures">
									Cucina ・ Camera ・ 1 bagno ・ 35mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/sei" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/TXO95fC.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> SEI </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Bilocale per 2 persone
								</span>
								<span className="siFeatures">
									Cucina ・ Camera ・ 1 bagno ・ 32mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/appartamenti/sette" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/l5usDiZ.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> SETTE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Trilocale per 4-6 persone
								</span>
								<span className="siFeatures">
									Cucina ・ 2 Camere ・ 1 bagno ・ 60mq
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda esterna</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Biancheria (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Cucina</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Lavanderia (€)</span>
									</div>
								</div>
							</div>
						</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
					</div>
				{/*</div>*/}
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<Footer />
		</div>
	);
}

export default AppList;


