import './header.css';

const Header = () =>{

return(
	<div className="sections">
		<section className="headerintro">
			<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	    </section>
    </div>
    );
}

export default Header;


