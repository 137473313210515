import './home.css';
import Header from '../../components/header/Header';
import WeareonEn from '../../components/WeareOn/Weareon_en';
import FooterEn from '../../components/footer/Footer_en';
import StrutturaAdEn from '../../components/StrutturaAd/StrutturaAd_en';
import OlioAdEn from '../../components/OlioAd/OlioAd_en';
import DisponibilitaAdEn from '../../components/DisponibilitàAd/DisponibilitaAd_en';
import ContattiAdEn from '../../components/ContattiAd/ContattiAd_en';
import BnbAdEn from '../../components/BnbAd/BnbAd_en';
import GuidaAdEn from '../../components/GuidaAd/GuidaAd_en';
import AppartamentiAdEn from '../../components/AppartamentiAd/AppartamentiAd_en';
import {useEffect} from 'react';
import {React} from 'react';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import { IT } from 'country-flag-icons/react/3x2';
import { GB } from 'country-flag-icons/react/3x2';
import {Link} from "react-router-dom";



const HomeEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	const availability = {
    '2024-04-25': { available: true, price: 100 },
    '2024-04-30': { available: false },
    // Continua con gli altri giorni dell'anno
  	};

	  const renderCell = (date) => {
	    const dateString = date.toISOString().slice(0, 10); // Ottieni la stringa della data (es. '2024-04-25')
	    const dayAvailability = availability[dateString];

	    if (!dayAvailability || !dayAvailability.available) {
	      return <Badge className="calendar-unavailable-badge" />;
	    }

	    return <Badge content={`$${dayAvailability.price}`} />;
	  };

	  const handleDateSelect = (date) => {
	    // Implementa la gestione della selezione della data se necessario
	    // console.log('Data selezionata:', date);
	  };	

	return (
		<div>
			{/*<Navbar />*/}
			<section className="len">
				<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
	        	<IT title="Italy" className="flag"/>
	        	</Link>
	        	<GB title="Great Britain" className="flag"/>
			</section>
			<Header />
			<div className="homeContainer">
				{/*<Featured />*/}
				<section className="vdvTitle">
		        	<h1> VALLE DEGLI ULIVI B&B </h1>
		        	<p>Discover our rooms</p>
	        	</section>
				<BnbAdEn />
				<section className="vdvTitle">
		        	<h1> VALLE DEGLI ULIVI APARTMENTS</h1>
		        	<p>Discover our apartments</p>
	        	</section>
				<AppartamentiAdEn />
				<section className="vdvTitle">
		        	<h1> HOMEPAGE </h1>
		        	<p>Discover all that Valle degli Ulivi has to offer</p>
	        	</section>
	        	<h1 className="homeTit">THE LOCATION</h1>
				<StrutturaAdEn />
				<h1 className="homeTit">EXTRA VIRGIN OLIVE OIL</h1>
				<OlioAdEn />
				<h1 className="homeTitle">Vieste, a city to discover: our guide!</h1>
				<GuidaAdEn />
				<h1 className="homeTit">AVAILABILITY</h1>
				<DisponibilitaAdEn />
				<h1 className="homeTit">CONTACT US</h1>
				<ContattiAdEn />
				<WeareonEn />
				<FooterEn />
			</div>
		</div>
	);
}

export default HomeEn;

