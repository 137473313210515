import './list.css';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import FooterEn from '../../components/footer/Footer_en';

const ListEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI B&B </h1>
	        	<p>Discover our rooms</p>
	        </section>
			<div className="listContainer">
					<div className="listResult">
						<div className="searchItem"> 
							<Link to="/len=en/bnbs/grecale" style={{color:"inherit", textDecoration:"none"}}>
							<img src="https://i.imgur.com/m1eucd6.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Grecale </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Double Room with View
								</span>
								<span className="siFeatures">
									Room ・ 1 bathroom ・ 28sqm ・ 1 full bed
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<GiCoffeePot className="react-icons"/>
										<span>Kettle</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcony</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Daily Cleaning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Italian Breakfast</span>
									</div>
								</div>
								<span className="siBreakOp">Breakfast included</span>
							</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
						</div>
						<div className="searchItem">
							<Link to="/len=en/bnbs/scirocco" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/TxQT3x8.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Scirocco </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Double Room with View
								</span>
								<span className="siFeatures">
									Room ・ 1 bathroom ・ 28sqm ・ 1 full bed
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<GiCoffeePot className="react-icons"/>
										<span>Kettle</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcony</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Daily Cleaning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Italian Breakfast</span>
									</div>
								</div>
								<span className="siBreakOp">Breakfast included</span>
							</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
						</div>
						<div className="searchItem">
							<Link to="/len=en/bnbs/libeccio" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/ULEeei0.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Libeccio </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Triple Room with View
								</span>
								<span className="siFeatures">
									Room ・ 1 bathroom ・ 35sqm ・ 1 full bed  ・ 1 single bed
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcony</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Daily Cleaning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Italian Breakfast</span>
									</div>
								</div>
								<span className="siBreakOp">Breakfast included</span>
							</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
						</div>
						<div className="searchItem">
							<Link to="/len=en/bnbs/maestrale" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/BJC0wn5.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Maestrale </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Double Deluxe with Hot Tub
								</span>
								<span className="siFeatures">
									Room ・ 1 bathroom ・ 40sqm ・ 1 full bed
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcony</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Daily Cleaning</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Italian Breakfast</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faHotTubPerson} className="react-icons"/>
										<span>Hot Tub</span>
									</div>
								</div>
								<span className="siBreakOp">Breakfast included</span>
							</div>
						</div>
					</div>
				{/*</div>*/}
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<FooterEn />
		</div>
	);
}

export default ListEn;

