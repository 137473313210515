import {React, useEffect} from 'react';
import './olio.css';
import OlioList from './OlioList';
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';
import {Link} from "react-router-dom";
import { LiaShippingFastSolid } from "react-icons/lia";


const Olio = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/HpCiLqc.jpeg" alt="" className="vdvImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> OLIO EXTRA VERGINE DI OLIVA </h1>
	        	<p>Scorri per ulteriori dettagli e acquistarlo!</p>
	        </section>
	        <section className="bottle">
				<img src="https://i.imgur.com/8cpwbuV.png" alt="" className="olioImg"/>
				<div className="olioDesc">
					<table width="600" border="0">
					<tr>
					<td width="50%"><b>PRODUTTORE</b></td>
					<td width="50%">Az. Agricola "Valle degli Ulivi"</td>
					</tr>
					<tr>
					<td width="50%"><b>LOCALITA'</b></td>
					<td width="50%">
						Centro aziendale immerso in uliveti secolari leggermente collinari,
						in pieno Parco Nazionale del Gargano
					</td>
					</tr>
					<tr>
					<td width="50%"><b>CULTIVAR DI PRODUZIONE</b></td>
					<td width="50%">Ogliarola Garganica 100%</td>
					</tr>
					<tr>
					<td width="50%"><b>RACCOLTA</b></td>
					<td width="50%">
						Il periodo di raccolta inizia ad Ottobre, in piena fase di
						invaiatura (l'oliva assume una colorazione tendente al violaceo)
					</td>
					</tr>
					<tr>
					<td width="50%"><b>COLORE</b></td>
					<td width="50%">Giallo Oro, media densità</td>
					</tr>
					<tr>
					<td width="50%"><b>METODO DI ESTRAZIONE</b></td>
					<td width="50%">
						Sinocea, estrazione completamente a freddo con
						temperature che non superano i 25 gradi durante
						tutta la lavorazione
					</td>
					</tr>
					<tr>
					<td width="50%"><b>ACIDITA'</b></td>
					<td width="50%">Da 0.2% a 0.35% di acido oleico</td>
					</tr>
					</table>
				</div>
	        </section>
	        <section className="vdvTitle">
	        	<h1> CARATTERISTICHE E PROPRIETA' DELL'OLIO</h1>
	        </section>
	        <section className="bottle">
	        	<img src="https://i.imgur.com/V78Kf78.jpeg" alt="" className="evoImg"/>
				<div className="olioDesc">
					<p className="oliocar">
						L'Olio Extra Vergine di Oliva è un alimento
						ricco di antiossidanti: uno su tutti l'acido oleico.
						Quest'ultimo, insieme all'acido linoleico ed ai polifenoli,
						sono vere e proprie sostanze antitumorali. <hr/>
						Anche l'acido linolenico (più comunemente conosciuto come
						Omega 3) costituisce un elemento essenziale
						dell'olio extra vergine in quanto è l'elemento che mantiene
						basso il livello di colesterolo nel sangue. <hr/>
						L'Olio Extra Vergine è un'ottima alternativa (nonchè certamente 
						più salutare) al burro e allo strutto. <hr/>
						In definitiva, il nostro olio, con la sua intensa fragranza
						fruttata e mandorlata, è garanzia di qualità.
					</p>
				</div>
			</section>
	        <section className="vdvTitle">
	        	<h1> I NOSTRI PRODOTTI </h1>
	        	<p>Scegli la quantità e acquistalo subito!</p>
	        </section>
	        <OlioList image = "https://i.imgur.com/8cpwbuV.png" />
	        <div className="mail">
				<div className="elements">
					<h1 className="mailTitle">SPEDIZIONE IN TUTTA ITALIA E ALL'ESTERO</h1>
					<div className="brandicons">
						<LiaShippingFastSolid className="shipping"/>
					</div>
	{/*				<span className="mailDesc">Sign up and we'll send the best</span>
					<div className="mailInputContainer">
						<input type="text" placeholder="Your Email" />
						<button>Subscribe</button>
					</div>*/}
				</div>
			</div>
			{/*<div className="fText">Copyright © 2010–2024 valledegliulivivieste.com™. Tutti i diritti riservati.</div>*/}
			<Footer />
	    </div>
	);
}

export default Olio;