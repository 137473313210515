import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Footer from '../../components/footer/Footer';

const Escursioni = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/qwVMzHV.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> ESCURSIONI E TOUR </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/8ulowJv.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Grotte Marine </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour in motobarca
						</span>
						<span className="spiaggeFeatures">
							Tour in barca di circa 3 ore alla scoperta della meravigliosa costa e grotte marine di Vieste. 
							Navigazione da Vieste a Baia delle Zagare, circa 12 miglia di litorale.
						</span>
						<span className="grotteFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="grotteicons">
							<Link to="https://maps.app.goo.gl/XNjyoRURyKYzAfrR8?g_st=com" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="grotteicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Scalo%20Marittimo%20S%201,%2071019%20Vieste%20FG,%20Italia&auid=9973533701663810080&ll=41.886291,16.179046&lsp=9902&q=Tour%20Paloma&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="grotteicon"/>
							</Link>
						</span>
						<span className="spiaggeFeatures">
							Acquista e ritira i biglietti presso noi:
						</span>
						<span className="grotteicons">
							<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20acquistare%20dei%20biglietti%20per%20il%20tour%20in%20barca" style={{color:"inherit", textDecoration:"none"}}>
				        	<FontAwesomeIcon icon={faWhatsapp} className="grotteicon" />
				        	</Link>
				        </span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/3Yvcr62.png" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Isole Tremiti </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour in traghetto
						</span>
						<span className="spiaggeFeatures">
							Le Isole Tremiti sono l’unico arcipelago italiano del mare Adriatico e si trovano a circa 12 miglia a largo del Gargano.
							Rappresentano un piccolo angolo di paradiso che attrae ogni anno migliaia di turisti grazie alle acque cristalline.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/EbpptyZz7FmtpY3PA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Cristoforo%20Colombo%2083,%2071019%20Vieste%20FG,%20Italia&auid=17394090604711950870&ll=41.887523,16.179236&lsp=9902&q=Gargano%20Viaggi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/7sh8Z3A.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tour privati costa </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour privati
						</span>
						<span className="spiaggeFeatures">
							Nell'eventualità in cui si fosse alla ricerca di tour più "intimi",
							è possibile organizzare escursioni su imbarcazioni private, come l'Albachiara o il Caicco Norita.
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/PSCb3CE.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Explora Gargano </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Escursioni in Quad/Jeep
						</span>
						<span className="spiaggeFeatures">
							Per gli amanti di paesaggi più "wild" come boschi e foreste,
							è possibile organizzare escursioni in Jeep o Quad nell'entroterra garganico. 
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/syZKwll.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tour cavallo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Escursioni a cavallo
						</span>
						<span className="spiaggeFeatures">
							Vieste offre inoltre la possibilità di provare l'esperienza di una passeggiata a cavallo,
							letteralmente tra "mari e monti", sia in spiaggia che in location più rurali.
						</span>
					</div>
				</div>
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<Footer />
		</div>
	);
}

export default Escursioni;



