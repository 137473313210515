import './list.css';
import '../../components/searchItem/searchItem.css';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSquareParking,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import Footer from '../../components/footer/Footer';

const List = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI B&B </h1>
	        	<p>Scopri le nostre camere</p>
	        </section>
			<div className="listContainer">
					<div className="listResult">
						<div className="searchItem"> 
							<Link to="/bnbs/grecale" style={{color:"inherit", textDecoration:"none"}}>
							<img src="https://i.imgur.com/m1eucd6.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Grecale </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Camera Matrimoniale con Vista Giardino
								</span>
								<span className="siFeatures">
									Camera ・ 1 bagno ・ 28mq ・ 1 letto matrimoniale
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<GiCoffeePot className="react-icons"/>
										<span>Bollitore</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcone con vista</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Pulizia giornaliera</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Colazione all'italiana</span>
									</div>
								</div>
								<span className="siBreakOp">Colazione inclusa</span>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/bnbs/scirocco" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/TxQT3x8.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Scirocco </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Camera Matrimoniale con Vista Giardino
								</span>
								<span className="siFeatures">
									Camera ・ 1 bagno ・ 28mq ・ 1 letto matrimoniale
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi </span>
									</div>
									<div className="siserviceListItem">
										<GiCoffeePot className="react-icons"/>
										<span>Bollitore</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcone con vista</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Pulizia giornaliera</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Colazione all'italiana</span>
									</div>
								</div>
								<span className="siBreakOp">Colazione inclusa</span>
							</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
						</div>
						<div className="searchItem">
							<Link to="/bnbs/libeccio" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/ULEeei0.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Libeccio </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Camera Tripla con Vista Giardino
								</span>
								<span className="siFeatures">
									Camera ・ 1 bagno ・ 35mq ・ 1 camera matrimoniale  ・ 1 letto singolo
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi </span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcone con vista</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Pulizia giornaliera</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Colazione all'italiana</span>
									</div>
								</div>
								<span className="siBreakOp">Colazione inclusa</span>
							</div>
							{/*<div className="siDetails">
								<div className="siRating">
									<span>Excellent</span>
									<button>8.9</button>
								</div>
								<div className="siDetailTexts">
									<span className="siPrice">€123</span>
									<span className="siTaxOp">Non include la tassa di soggiorno</span>
									<button className="siCheckButton">See availability</button>
								</div>
							</div>*/}
						</div>
						<div className="searchItem">
							<Link to="/bnbs/maestrale" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/BJC0wn5.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> Maestrale </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Camera Matrimoniale Deluxe con Vasca
								</span>
								<span className="siFeatures">
									Camera ・ 1 bagno ・ 40mq ・ 1 letto matrimoniale
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Aria Condizionata</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Parcheggio interno</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi </span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Macchina del caffè</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Balcone con vista</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>Smart TV</span>
									</div>
									<div className="siserviceListItem">
										<MdDryCleaning className="react-icons"/>
										<span>Pulizia giornaliera</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faMugSaucer} className="react-icons"/>
										<span>Colazione all'italiana</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faHotTubPerson} className="react-icons"/>
										<span>Vasca idromassaggio</span>
									</div>
								</div>
								<span className="siBreakOp">Colazione inclusa</span>
							</div>
						</div>
					</div>
				{/*</div>*/}
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<Footer />
		</div>
	);
}

export default List;