import '../bnb/bnb.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBuilding} from '@fortawesome/free-solid-svg-icons';
import Weareon from '../../components/WeareOn/Weareon';
import Footer from '../../components/footer/Footer';
import useFetch from "../../hooks/useFetch";
import {useState, useEffect} from 'react';
import {React} from 'react';
import { Calendar, Badge } from 'rsuite';
import 'rsuite/Calendar/styles/index.css';
import {Link} from "react-router-dom";
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import {faSquareParking,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { GiTowel } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";

const Uno = () => {

useEffect(() => {
window.scrollTo(0, 0);
  }, []);

  const { data, loading, error } = useFetch("/api/apartments/uno/");

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;
    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? photos.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === photos.length - 1 ? 0 : slideNumber + 1;
    }
    setSlideNumber(newSlideNumber);
  };

  // Verifica se i dati stanno caricando
  if (loading) {
    return <div>Loading...</div>;
  }

  // Verifica se c'è un errore
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Verifica se i dati sono definiti e non sono vuoti
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  // Verifica la struttura dei dati
  console.log('Fetched data:', data);

  if (!data[0].availability) {
    return <div>No availability data</div>;
  }

  const ciao = data[0].availability;

  const renderCell = (date) => {
    const dateString = date.toISOString().slice(0, 10); // Ottieni la stringa della data (es. '2024-04-25')

    // Trova la disponibilità corrispondente alla data selezionata
    const dayAvailability = ciao.find(item => item.availableDate === dateString);

    if (!dayAvailability || !dayAvailability.available) {
      return <Badge className="calendar-unavailable-badge" />;
    }

    return <Badge content={`€${dayAvailability.price}`} />;
  };

	const photos = [
		{
			src: "https://i.imgur.com/4EmUA02.jpeg"
		},

		{
			src: "https://i.imgur.com/roG4HVo.jpeg"
		},

		{
			src: "https://i.imgur.com/7typeDr.jpeg"
		},

		{
			src: "https://i.imgur.com/OyMQYxe.jpeg"
		},

		{
			src: "https://i.imgur.com/ABQ5ZaW.jpeg"
		},

		{
			src: "https://i.imgur.com/B0fsBfi.jpeg"
		},

		{
			src: "https://i.imgur.com/zhvwa4a.jpeg"
		}

	];

	return(
		<div>
			<Link to="/appartamenti" style={{color:"inherit", textDecoration:"none"}}>
			<section className="optionintro">
			<img src="https://i.imgur.com/BCfRaeg.png"/>
	    	</section>
	    	</Link>
			<div className="bContainer">
				{open && <div className="slider">
					<FontAwesomeIcon icon={faCircleXmark} className="close" onClick={()=>setOpen(false)}/>
					<FontAwesomeIcon icon={faCircleArrowLeft} className="arrow" onClick={()=>handleMove("l")}/>
					<div className="sliderWrapper">
						<img src={photos[slideNumber].src} alt="" className="sliderImg" />
					</div>
					<FontAwesomeIcon icon={faCircleArrowRight} className="arrow" onClick={()=>handleMove("r")}/>
					</div>}
				<div className="bWrapper">
					<Link to="https://wa.me/3475952559?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20appartamento%20Uno" style={{color:"inherit", textDecoration:"none"}}>
					<button className="bookNow">
						Prenota ora!
					</button>
					</Link>
					<h1 className="siTitle"> UNO </h1>
					<span className="siSubtitle">
						Trilocale per 4-6 persone
					</span>
					<span className="siCaratt">
						Cucina ・ 2 Camere ・ 1 bagno ・ 55mq
					</span>
					<div className="bAddress">
						<Link to="https://maps.app.goo.gl/P2xzjzb56cSXy2Nx8?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
						<FontAwesomeIcon icon={faLocationDot} className="geodot"/>
						<span>Contrada Cerasa, 20/b, Vieste, 71019</span>
						</Link>
					</div>
{/*					<span className="bDistance">
						Excellent location - 500m from center
					</span>
					<span className="bPriceHighlight">
						Prenota e avrai gratis ...
					</span>*/}
					<div className="bImages">
						{photos.map((photo,i) =>(
							<div className="bImgWrapper">
								<img onClick={()=>handleOpen(i)} src={photo.src} alt="" className="bImg" />
							</div>
						))}
					</div>
					<div className="bDetails">
						<div className="bDetailsTexts">
							<h1 className="bTitle">Trilocale con Ampia Veranda</h1>
							<p className="bDesc">
								Gli appartamenti di Valle degli Ulivi si trovano al piano terra di una palazzina di recente costruzione.<hr/>
								L'appartamento Uno è un trilocale di 55mq, composto da: cucina, 2 camere da letto, bagno privato. E' la soluzione perfetta per due coppie o una famiglia di 4-6 persone, in cerca di pace e relax.
								L'appartamento è dotato di tutti i comfort: Wi-Fi, angolo cottura (attrezzati di pentole , stoviglie, caffettiera e quant'altro), bagno privato, tv a schermo piatto, cassaforte, asciugacapelli, prodotti da bagno ( shampoo, ecc) e detersivo per stoviglie.<hr/>
								Inoltre, tutti gli alloggi sono dotati di ingresso indipendente e di una veranda esterna attrezzata di tavolo e sedie per poter mangiare comodamente all'aperto. <hr/>
								All'esterno, a disposizione degli ospiti: piscina con area solarium composta da lettini e sdraio, un vasto prato, barbecue, lavatoi esterni e un parco giochi per bambini.<hr/>
								A circa due minuti di macchina ( 1500 m circa ) si trova la spiaggia di S. Lorenzo, con sabbia dorata finissima, mentre quella di Pizzomunno è a 2,1 Km. 
								Dalla struttura è possibile fare passeggiate rilassanti nel verde della zona oppure raggiungere la Foresta Umbra a soli 5-6 Km o raggiungere un maneggio a soli 2 Km per chi ama le passeggiate a cavallo. Il verde è il colore predominante, non manca il silenzio per rilassarsi in tutta tranquillità. <hr/>
								Michele e Leonardo, entusiasti di ospitarvi, sono sempre a disposizione degli ospiti per qualsiasi informazione su luoghi da visitare o prodotti da degustare. Non esitate a chiederci dove trovare i migliori ristoranti e agriturismi per poter gustare piatti tipici locali o masserie dove degustare e acquistare formaggi e latticini locali. 
							</p>
							<h1 className="bTitle">La Struttura - Da Sapere</h1>
							<p className="bDesc">
								Il check-in è dalle 15:00 alle 18:00. Si prega gentilmente di comunicare in anticipo l'ora di arrivo. Purtroppo non è possibile in alcun modo anticipare il check-in.<hr/>
								Il check-out è dalle 07:00 alle 10:00. Si prega gentilmente di rispettare l'orario di check-out, al fine di permettere la pulizia delle camere per gli ospiti successivi.<hr/>
								Al check-in gli ospiti devono esibire un documento d'identità con foto. Siete pregati di notare che le Richieste Speciali sono soggette a disponibilità, e potrebbero comportare l'addebito di un supplemento.<hr/>
								La biancheria da letto e da bagno NON E' inclusa nel prezzo. E' possibile arrivare già muniti oppure noleggiarla in struttura al prezzo di:
								<ul>
									<li>Da bagno: 5€ per persona, per soggiorno</li>
									<li>Da letto: 5€ per persona, per soggiorno</li>
								</ul>
								La piscina è aperta nel periodo 01/06-30/09, nelle fasce orarie: 09:30 - 12:30   /    16:30 - 19:30. <hr/>
								L'aria condizionata è disponibile, su richiesta, al prezzo 5€ al giorno. <hr/>
								Si prega i gentili ospiti di munirsi di cuffia per l'utilizzo della piscina. <hr/>
							</p>
						</div>
						<div className="bDetailsPrice">
							<h1>Perfetto per 4-6 persone</h1>
							<span>
								Situati a 1500m dal mare e dal centro, abbiamo un punteggio di 8.4 su Booking.com!
							</span>
							<a href="https://wa.me/3475952559?text=Ciao%21%20Vorrei%20informazioni%20aggiuntive%20sull'%20appartamento%20Uno" class="text-decoration-none">
								<button className="linkbut">
									Prenota ora!
								</button>
							</a>
						</div>
					</div>
					<div className="bServices">
						<div className="bServicesTexts">
							<h1 className="bTitle">I nostri servizi</h1>
						</div>
						<div className="serviceList">
							<div className="serviceListItem">
								<TbAirConditioning className="bnbicon"/>
								<span>Aria Condizionata (€)</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faSquareParking} className="bnbicon"/>
								<span>Parcheggio interno</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faWifi} className="bnbicon"/>
								<span>WiFi</span>
							</div>
							<div className="serviceListItem">
								<MdCoffeeMaker className="bnbicon"/>
								<span>Macchina del caffè</span>
							</div>
							<div className="serviceListItem">
								<MdBalcony className="bnbicon"/>
								<span>Veranda esterna</span>
							</div>
							<div className="serviceListItem">
								<FontAwesomeIcon icon={faTv} className="bnbicon"/>
								<span>TV</span>
							</div>
							<div className="serviceListItem">
								<GiTowel className="bnbicon"/>
								<span>Biancheria (€)</span>
							</div>
							<div className="serviceListItem">
								<FaKitchenSet className="bnbicon"/>
								<span>Cucina</span>
							</div>
							<div className="serviceListItem">
								<GiWashingMachine className="bnbicon"/>
								<span>Lavanderia (€)</span>
							</div>
						</div>
						<div className="bServicesTexts">
							<h1 className="bTitle">Le nostre disponibilità</h1>
							<span className="bspan">*I prezzi non includono la tassa di soggiorno, pari a 2,50€ per persona per notte</span>
							<span className="bspan">*I prezzi di seguito si riferiscono ad un soggiorno di 4 persone.</span>
						</div>	
						<div className="cal">
							<Calendar
					            bordered
					            renderCell={renderCell}
					        />
			    		</div>					
					</div>
				</div>
			</div>
			<div className="lastitems">
				<Weareon />
				<Footer />
			</div>
		</div>
	);
}

export default Uno;

