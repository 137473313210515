import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import './spiagge.css';
import FooterOspiti from '../../components/footer/FooterOspiti';


const Parcheggio = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/me7qkqJ.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> DOVE PARCHEGGIARE </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/WLRJMAk.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Molo Turistico </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							Situato in prossimità del nuovo molo turistico, questa enorme area destinata 
							al parcheggio è perfetta per chi desidera raggiungere il centro, distante poche
							centinaia di metri.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/yys2NR6Az7fxJuWN9?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?ll=41.885089,16.175079&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/ysUs6sT.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Parcheggio Europa </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeFeatures">
							Situato a ridosso del lungomare Europa, questa area destinata al parcheggio
							è perfetta per chi desidera raggiungere velocemente la spiaggia o il centro.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/pffY24JFsEZgZn3u8?g_st=iw" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?ll=41.886172,16.167375&q=Segnaposto%20inserito&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">NON DIMENTICARE DI ACQUISTARE IL NOSTRO OLIO EVO!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspiti />
		</div>
	);
}

export default Parcheggio;



