import {React,useEffect} from 'react';
import './vdv.css';
import {Link} from "react-router-dom";
import { GiOlive } from "react-icons/gi";
import { FaOilCan } from "react-icons/fa";
import { LiaShippingFastSolid } from "react-icons/lia";
import FooterEn from '../../components/footer/Footer_en';


const VdVEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI </h1>
	        	<p>Discover our wonderful location</p>
	        </section>
	        <div className="vdvImages">
	        	<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/SQFBifW.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">LOCATION</h1>
							<p className="midDesc">The Valle degli Ulivi accommodation facility was founded in 2010, 
								but has undergone several renovation works over the years. 
								The apartments are on the ground floor, while the B&B rooms are on the second (and last) floor. <hr/>
								The structure is also surrounded by 2 hectares of centuries-old olive grove: 
								there is certainly no shortage of space!
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/YzO1oHb.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">SWIMMING POOL</h1>
							<p className="midDesc">Built in 2024, 
								our swimming pool will accompany you throughout your stay, 
								guaranteeing you the well-being and relaxation you need.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/j3rLWJg.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">SOLARIUM</h1>
							<p className="midDesc">The Solarium area is designed for those who want to enjoy 
								some well-deserved rest surrounded by nature, 
								or for those who simply want to tan away from crowded beaches.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/zluV65F.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">BARBECUE</h1>
							<p className="midDesc">Valle degli Ulivi offers its guests a lava stone barbecue, 
								on which it is possible to cook excellent local fish (or meat).
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/xJQg4uo.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">PARKING</h1>
							<p className="midDesc">Valle degli Ulivi also offers its guests a secure and 
								fenced internal car park, subject to 24-hour surveillance.
							</p>		
						</div>
				</div>
				<div className="vdvImgWrapper">
					<img src="https://i.imgur.com/bukprWI.jpeg" alt="" className="vdvImg"/>
						<div className="middle">
							<h1 className="midTitle">PLAYGROUND</h1>
							<p className="midDesc">To the delight of children (and perhaps also parents), 
								Valle degli Ulivi has a playground for children, 
								where they can unwind and have fun without limits.
							</p>		
						</div>
				</div>
			</div>
			<Link to="/len=en/olio" style={{color:"inherit", textDecoration:"none"}}>
			<div className="mail">
			<div className="elements">
				<h1 className="mailTitle">Discover our Olive Oil</h1>
				<div className="brandicons">
					<GiOlive className="oilicon"/>
					<FaOilCan className="oilicon"/>
					<LiaShippingFastSolid className="oilicon"/>
				</div>
{/*				<span className="mailDesc">Sign up and we'll send the best</span>
				<div className="mailInputContainer">
					<input type="text" placeholder="Your Email" />
					<button>Subscribe</button>
				</div>*/}
			</div>
			</div>
			</Link>
			<FooterEn />
 		</div>
	);
}

export default VdVEn;



