import "./contattiAd.css";
import {Link} from "react-router-dom";

const ContattiAd = () => {
	return(
		<div className="contattiAd">
			<Link to="/contatti" style={{color:"inherit", textDecoration:"none"}}>
			<div className="contattiItem">
				<img src="https://i.imgur.com/sZC9PAR.jpeg" className="contattiImg"/>
			</div>
			</Link>
		</div>
	);
}

export default ContattiAd;


