import {React, useEffect} from 'react';
import './calendario.css';
import {Link} from "react-router-dom";
import WeareonEn from '../../components/WeareOn/Weareon_en';
import FooterEn from '../../components/footer/Footer_en';


const CalendarioEn = () => {

  useEffect(()=>{
  	window.scrollTo(0,0);
  },[])

  return (
    <div className="sections">
      <Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
      <section className="intro">
        <img src="https://i.imgur.com/JVY6ULw.jpeg" alt="" className="vdvImg" />
      </section>
      </Link>
      <section className="vdvTitle">
        <h1> B&B ROOMS AVAILABILITY </h1>
        <p>Scroll to discover our availability</p>
      </section>
      <div className="dispbnbAd">
      	<Link to="/len=en/bnbs/grecale" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/m1eucd6.jpeg" className="dispbnbAdImg" />
		  		<div className="dispbnbAdTitles">
						<h1>
							<b>GRECALE</b>				
						</h1>
		  		</div>
		  	</div>
		  	</Link>
			  <Link to="/len=en/bnbs/scirocco" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/TxQT3x8.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>SCIROCCO</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/bnbs/libeccio" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/ULEeei0.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>LIBECCIO</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/bnbs/maestrale" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/BJC0wn5.jpeg" className="dispbnbAdImg" />
			  		<div className="dispbnbAdTitles">
							<h1>
								<b>MAESTRALE</b>				
							</h1>
			  		</div>
			  </div>
			  </Link>
	  </div>
	  <section className="vdvTitle">
        <h1> DISPONIBILITA' APPARTAMENTI </h1>
        <p>Scorri per scoprire le nostre disponibilità</p>
      </section>
      <div className="dispbnbAd">
      	<Link to="/len=en/appartamenti/uno" style={{color:"inherit", textDecoration:"none"}}>
	      <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/AWVYfuI.jpeg" className="dispbnbAdImg" />
		  			<div className="dispappAdTitles">
							<h1>
								<b>ONE</b>				
							</h1>
		  			</div>
		  	</div>
		  	</Link>
		  	<Link to="/len=en/appartamenti/due" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/xHfvG17.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>TWO</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/appartamenti/tre" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/3Egc8G7.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>THREE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/appartamenti/quattro" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/qftn8d0.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>FOUR</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/appartamenti/cinque" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/07ZOUZT.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>FIVE</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/appartamenti/sei" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/TXO95fC.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>SIX</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
			  <Link to="/len=en/appartamenti/sette" style={{color:"inherit", textDecoration:"none"}}>
			  <div className="dispbnbAdItem">
					<img src="https://i.imgur.com/l5usDiZ.jpeg" className="dispbnbAdImg" />
			  		<div className="dispappAdTitles">
						<h1>
							<b>SEVEN</b>				
						</h1>
			  		</div>
			  </div>
			  </Link>
	  	</div>
	  <WeareonEn />
	  <FooterEn />
    </div>
  );
};

export default CalendarioEn;