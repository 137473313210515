import './list.css';
import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { MdBalcony } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdCoffeeMaker } from "react-icons/md";
import { GiCoffeePot } from "react-icons/gi";
import { MdDryCleaning } from "react-icons/md";
import { GiTowel } from "react-icons/gi";
import { FaKitchenSet } from "react-icons/fa6";
import { GiWashingMachine } from "react-icons/gi";
import FooterEn from '../../components/footer/Footer_en';


const AppListEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	// const location = useLocation();
	// const [destination, setDestination] = useState(location.state.destination)
	// const [date, setDate] = useState(location.state.date)
	// const [openDate, setOpenDate] = useState(false)
	// const [options, setOptions] = useState(location.state.options)

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en" style={{color:"inherit", textDecoration:"none"}}>
			<section className="headerintro">
				<img src="https://i.imgur.com/vnEkRet.png" className="strutturaImg"/>
	        </section>
	        </Link>
	        <section className="vdvTitle">
	        	<h1> VALLE DEGLI ULIVI APARTMENTS </h1>
	        	<p>Discover our apartments</p>
	        </section>
			<div className="listContainer">
					<div className="listResult">
						<div className="searchItem"> 
							<Link to="/len=en/appartamenti/uno" style={{color:"inherit", textDecoration:"none"}}>
							<img src="https://i.imgur.com/AWVYfuI.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> ONE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Three-Room Apartment for 4-6 people
								</span>
								<span className="siFeatures">
									Kitchen ・ 2 Bedrooms ・ 1 Bathroom ・ 55sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/due" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/xHfvG17.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> TWO </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Studio Apartment for 2 people
								</span>
								<span className="siFeatures">
									Kitchen/Bedroom ・ 1 Bathroom ・ 34sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/tre" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/3Egc8G7.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> THREE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Studio Apartment for 2 people
								</span>
								<span className="siFeatures">
									Kitchen/Bedroom ・ 1 Bathroom ・ 35sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/quattro" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/qftn8d0.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> FOUR </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Studio Apartment for 2 people
								</span>
								<span className="siFeatures">
									Kitchen/Bedroom ・ 1 Bathroom ・ 36sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/cinque" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/07ZOUZT.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> FIVE </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Two-Room Apartment for 2 people
								</span>
								<span className="siFeatures">
									Kitchen ・ Bedroom ・ 1 Bathroom ・ 35sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/sei" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/TXO95fC.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> SIX </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Two-Room Apartment for 2 people
								</span>
								<span className="siFeatures">
									Kitchen ・ Bedroom ・ 1 Bathroom ・ 32sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
						<div className="searchItem">
							<Link to="/len=en/appartamenti/sette" style={{color:"inherit", textDecoration:"none"}}> 
							<img src="https://i.imgur.com/l5usDiZ.jpeg" alt="" className="siImg" />
							</Link>
							<div className="siDesc">
								<h1 className="siTitle"> SEVEN </h1>
								{/*<span className="siDistance">500m from center </span>*/}
								<span className="siSubtitle">
									Three-Room Apartment for 4-6 people
								</span>
								<span className="siFeatures">
									Kitchen ・ 2 Bedrooms ・ 1 Bathroom ・ 60sqm
								</span>
								{/*<span className="siCancelOp">Free cancellation up to 14 days </span>
								<span className="siCancelOpSubtitle">
									You can cancel later, so lock in this great price today!
								</span>*/}
								<div className="siserviceList">
									<div className="siserviceListItem">
										<TbAirConditioning className="react-icons"/>
										<span>Air Conditioning (€)</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faSquareParking} className="react-icons"/>
										<span>Internal Parking</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faWifi} className="react-icons"/>
										<span>WiFi</span>
									</div>
									<div className="siserviceListItem">
										<MdCoffeeMaker className="react-icons"/>
										<span>Coffee Machine</span>
									</div>
									<div className="siserviceListItem">
										<MdBalcony className="react-icons"/>
										<span>Veranda</span>
									</div>
									<div className="siserviceListItem">
										<FontAwesomeIcon icon={faTv} className="react-icons"/>
										<span>TV</span>
									</div>
									<div className="siserviceListItem">
										<GiTowel className="react-icons"/>
										<span>Linen and towels (€)</span>
									</div>
									<div className="siserviceListItem">
										<FaKitchenSet className="react-icons"/>
										<span>Kitchen</span>
									</div>
									<div className="siserviceListItem">
										<GiWashingMachine className="react-icons"/>
										<span>Laundromat (€)</span>
									</div>
								</div>
							</div>
						</div>
					</div>
			</div>
			<hr style={{ border: '1px solid grey', width: '80%', margin: '40px auto 20px auto' }} />
			<FooterEn />
		</div>
	);
}

export default AppListEn;


