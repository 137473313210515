import "./contattiAd.css";
import {Link} from "react-router-dom";

const ContattiAdEn = () => {
	return(
		<div className="contattiAd">
			<Link to="/len=en/contatti" style={{color:"inherit", textDecoration:"none"}}>
			<div className="contattiItem">
				<img src="https://i.imgur.com/sZC9PAR.jpeg" className="contattiImg" />
			</div>
			</Link>
		</div>
	);
}

export default ContattiAdEn;