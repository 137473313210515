import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FooterOspiti from '../../components/footer/FooterOspiti';

const OspitiEscursioni = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	// const location = useLocation();
	// const [destination, setDestination] = useState(location.state.destination)
	// const [date, setDate] = useState(location.state.date)
	// const [openDate, setOpenDate] = useState(false)
	// const [options, setOptions] = useState(location.state.options)

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/qwVMzHV.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> ESCURSIONI E TOUR </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/8ulowJv.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Grotte Marine </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour in motobarca
						</span>
						<span className="spiaggeFeatures">
							Tour in barca di circa 3 ore alla scoperta della meravigliosa costa e grotte marine di Vieste. 
							Navigazione da Vieste a Baia delle Zagare, circa 12 miglia di litorale.
						</span>
						<span className="grotteFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="grotteicons">
							<Link to="https://maps.app.goo.gl/XNjyoRURyKYzAfrR8?g_st=com" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Scalo%20Marittimo%20S%201,%2071019%20Vieste%20FG,%20Italia&auid=9973533701663810080&ll=41.886291,16.179046&lsp=9902&q=Tour%20Paloma&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
						<span className="spiaggeFeatures">
							Acquista e ritira i biglietti presso noi:
						</span>
						<span className="grotteicons">
							<Link to="https://wa.me/3409494027?text=Ciao%21%20Vorrei%20acquistare%20dei%20biglietti%20per%20il%20tour%20in%20barca" style={{color:"inherit", textDecoration:"none"}}>
				        	<FontAwesomeIcon icon={faWhatsapp} className="grotteicon" />
				        	</Link>
				        </span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/3Yvcr62.png" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Isole Tremiti </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour in traghetto
						</span>
						<span className="spiaggeFeatures">
							Le Isole Tremiti sono l’unico arcipelago italiano del mare Adriatico e si trovano a circa 12 miglia a largo del Gargano.
							Rappresentano un piccolo angolo di paradiso che attrae ogni anno migliaia di turisti grazie alle acque cristalline.
						</span>
						<span className="spiaggeFeatures">
							Clicca di seguito per le indicazioni:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/8rvf21AzQEUhA6iw6?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Scalo%20Marittimo%20S,%2071019%20Vieste%20FG,%20Italia&auid=9660262133009884978&ll=41.886161,16.178373&lsp=9902&q=Desir%C3%A8e%20imbarco%20Tour%20Grotte%20Marine&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/7sh8Z3A.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tour privati costa </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Tour privati
						</span>
						<span className="spiaggeFeatures">
							Nell'eventualità in cui si fosse alla ricerca di tour più "intimi",
							è possibile organizzare escursioni su imbarcazioni private, come l'Albachiara o il Caicco Norita.
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/PSCb3CE.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Explora Gargano </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Escursioni in Quad/Jeep
						</span>
						<span className="spiaggeFeatures">
							Per gli amanti di paesaggi più "wild" come boschi e foreste,
							è possibile organizzare escursioni in Jeep o Quad nell'entroterra garganico. 
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/syZKwll.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tour cavallo </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Escursioni a cavallo
						</span>
						<span className="spiaggeFeatures">
							Vieste offre inoltre la possibilità di provare l'esperienza di una passeggiata a cavallo,
							letteralmente tra "mari e monti", sia in spiaggia che in location più rurali.
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">NON DIMENTICARE DI ACQUISTARE IL NOSTRO OLIO EVO!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspiti />
		</div>
	);
}

export default OspitiEscursioni;



