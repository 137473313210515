import "./footer.css";
import {Link} from "react-router-dom";

const Footer = () => {
	return(
		<div className="footer">
			<div className="fLists">
				<ul className="fList">
					<Link to="/" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Home</li>
					</Link>
					<Link to="/contatti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Contatti</li>
					</Link>
					<Link to="/disponibilita" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Disponibilità</li>
					</Link>
					<Link to="/guida" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">La Nostra Guida</li>
					</Link>
					<Link to="/bnbs" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">B&B</li>
					</Link>
					<Link to="/appartamenti" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Appartamenti</li>
					</Link>
					<Link to="/olio" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">Olio EVO</li>
					</Link>
					<Link to="/vdv" style={{color:"inherit", textDecoration:"none"}}>
					<li className="fListItem">La Nostra Struttura</li>
					</Link>
				</ul>
			</div>
			<div className="fText">Copyright © 2010–2024 valledegliulivivieste.com™. Tutti i diritti riservati.</div>
		</div>
	);
}

export default Footer;