import {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBuilding,faSquareParking,faMountainCity,faTv,faBroom,faMugSaucer,
faHotTubPerson,faFan, faWifi, faMugHot} from '@fortawesome/free-solid-svg-icons';
import { SiGooglemaps } from "react-icons/si";
import { FaApple } from "react-icons/fa";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import FooterOspitiEn from '../../components/footer/FooterOspitiEn';

const OspitiEscursioniEn = () => {

	useEffect(()=>{
  		window.scrollTo(0,0);
	},[])

	return (
		<div className="sections">
			{/*<section className="home">
				<div className="overlay-image"></div>
			</section>*/}
			<Link to="/len=en/ospiti/guida" style={{color:"inherit", textDecoration:"none"}}>
			<section className="intro">
				<img src="https://i.imgur.com/qwVMzHV.jpeg" />
	        </section>
	        </Link>
	        <section className="spiaggesec">
	        	<h1> EXCURSIONS AND TOURS </h1>
	        </section>
	        <div className="spiagge">
	        	<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/8ulowJv.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Sea Caves </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Motorboat tour
						</span>
						<span className="spiaggeFeatures">
							Boat tour of about 3 hours to discover the wonderful coast and sea caves of Vieste. 
							Navigation from Vieste to Baia delle Zagare, approximately 12 miles of coastline.
						</span>
						<span className="grotteFeatures">
							Click below for directions:
						</span>
						<span className="grotteicons">
							<Link to="https://maps.app.goo.gl/XNjyoRURyKYzAfrR8?g_st=com" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Scalo%20Marittimo%20S%201,%2071019%20Vieste%20FG,%20Italia&auid=9973533701663810080&ll=41.886291,16.179046&lsp=9902&q=Tour%20Paloma&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
						<span className="spiaggeFeatures">
							Tickets available from us:
						</span>
						<span className="grotteicons">
							<Link to="https://wa.me/3409494027?text=Hi%21%20I'd%20like%20to%20buy%20some%20tickets%20for%20the%20boat%20tour" style={{color:"inherit", textDecoration:"none"}}>
				        	<FontAwesomeIcon icon={faWhatsapp} className="grotteicon" />
				        	</Link>
				        </span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/3Yvcr62.png" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Tremiti Islands </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Ferry tour
						</span>
						<span className="spiaggeFeatures">
							The Tremiti Islands are the only Italian archipelago in the Adriatic Sea 
							and are located about 12 miles off the coast of the Gargano. 
							They represent a small corner of paradise that attracts thousands of tourists every year thanks to their crystal clear waters.
						</span>
						<span className="spiaggeFeatures">
							Click below for directions:
						</span>
						<span className="locicons">
							<Link to="https://maps.app.goo.gl/EbpptyZz7FmtpY3PA?g_st=ic" style={{color:"inherit", textDecoration:"none"}}>
							<SiGooglemaps className="locicon"/>
							</Link>
							<Link to="https://maps.apple.com/?address=Lungomare%20Cristoforo%20Colombo%2083,%2071019%20Vieste%20FG,%20Italia&auid=17394090604711950870&ll=41.887523,16.179236&lsp=9902&q=Gargano%20Viaggi&t=m" style={{color:"inherit", textDecoration:"none"}}>
							<FaApple className="locicon"/>
							</Link>
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/7sh8Z3A.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Private tours coast </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Private tours
						</span>
						<span className="spiaggeFeatures">
							In the event that you are looking for more "intimate" tours, 
							it is possible to organize excursions on private boats, 
							such as the Albachiara or the Gulet Norita.
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/PSCb3CE.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Explora Gargano </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Quad/Jeep excursions
						</span>
						<span className="spiaggeFeatures">
							For lovers of more "wild" landscapes such as woods and forests, 
							it is possible to organize Jeep or Quad excursions in the Gargano hinterland.
						</span>
					</div>
				</div>
				<div className="spiaggeItem">
	        		<img src="https://i.imgur.com/syZKwll.jpeg" alt="" className="spiaggeImg" />
					<div className="spiaggeDesc">
						<h1 className="spiaggeTitle"> Horseback riding tours </h1>
						{/*<span className="siDistance">500m from center </span>*/}
						<span className="spiaggeSubtitle">
							Horseback riding
						</span>
						<span className="spiaggeFeatures">
							Vieste also offers the possibility of trying the experience of a horse ride, literally between "seas and mountains", 
							both on the beach and in more rural locations.
						</span>
					</div>
				</div>
			</div>
			<div className="oliopub">
			  	<h1 className="oliopubTit">DO NOT FORGET TO PURCHASE OUR OLIVE OIL!</h1>
			  	<img src="https://i.imgur.com/hR1XdpH.jpeg" alt="" className="vdvImg"/>
	  		</div>
	  		<FooterOspitiEn />
		</div>
	);
}

export default OspitiEscursioniEn;



